import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store";
import PhoneDisplay from "../components/layout/PhoneDisplay";

const HomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];
    const incomingCountry = document.location.search.split('?')[2]?.split('country=')[1];

    const [screen_width, setScreenWidth] = useState(window.screen.width);
    const [authContent, setAuthContent] = useState(<></>)

    useEffect(()=>{
        if(incomingToken){
            // navigate('/welcome', {state: {token: incomingToken}})
            dispatch(authActions.saveCountry(incomingCountry))
        }
    },[incomingToken])

    window.addEventListener("resize", (event) => {
        setScreenWidth(window.screen.width);
      });
    
      useEffect(() => {
        if(screen_width < 500 && /iPhone/.test(navigator.userAgent)) {
          setAuthContent(<PhoneDisplay/>)
        } else {
       setAuthContent(<Outlet/>)
        }
      },[screen_width])

    return <>{authContent}</>
};

export default HomePage;