import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectLanguage from "../components/layout/SelectLanguage";
import ayamedicaIcon from "../assets/images/AyaMedica-Login.png";
import ayamedicaPanal from "../assets/images/ayamedicaPanal.jpg";
import words from "../assets/words";
import SelectInput from "../components/ui/SelectInput";
import Button from "../components/ui/Button";
import { authActions } from "../store";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { READ_AYAMEDICA_COUNTRIES } from "../graphql/auth/Queries";
import classes from "./SelectCountryPage.module.scss";

const SelectCountry = () => {
  const language = useSelector((state) => state.auth.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState("");

  const { loading: countryLoader } = useQuery(READ_AYAMEDICA_COUNTRIES, {
    variables: { country: null },
    onCompleted: (data) => {
      let arr = []
      data?.readAyamedicaCountries?.data?.map((item) => {
          arr.push({
            label: item.value,
            value: item.key,
            fixed: false
          })
      })
      setCountriesList(arr)
      // setCountriesList(
      //   selectInput(data?.readAyamedicaCountries?.data, "key", "value")
      // );
      // setCountriesList([{label: "Egypt", value: "EN"}])
    },
  });

  const selectCountryHandler = () => {
    dispatch(authActions.saveCountry(country.value));
    navigate("/auth");
  };

  useEffect(() => {
    if(sessionStorage.getItem("token")){
      dispatch(authActions.logout())
    }
  },[])

  return (
    <section className={classes.section}>
      <div className={classes.imagePannel} style={{ height: "100vh" }}>
        <img src={ayamedicaPanal} alt="Ayamedica Panal" />
      </div>

      <div className={classes.content}>
        <div className={classes.selectLanguage}>
          <div className={classes.userData}>
            <SelectLanguage language={language} />
          </div>
        </div>

        <main>
          <div className={classes.image}>
            <img src={ayamedicaIcon} alt="Ayamedica Logo" />
          </div>

          <div className={classes.countryContainer}>
            <div className={`${classes.title} noSelect`}>
              <h3>{words.WELCOME[language]}</h3>
              <h6>{words.SELECT_COUNTRY_PARAGRAPGH[language]}</h6>
            </div>
            <SelectInput
              placeholder={words.SELECT_COUNTRY[language]}
              name="country"
              value={country}
              options={countriesList}
              onChange={(e) => {
                setCountry(e)
              }}
              isLoading={countryLoader}
            />


            <Button onClick={selectCountryHandler} disabled={!country}>
              {words.LOG_IN[language]}
            </Button>
          </div>
        </main>
      </div>
    </section>
  );
};

export default SelectCountry;
