import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Button from "../ui/Button";
import words from "../../assets/words";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../graphql/auth/Mutations";
import { authActions } from "../../store";
import Input from "../ui/Input";
import PhoneNumberInput from "../ui/PhoneNumberInput";
import { formatPhoneNumber } from "../../util/formatWords";
import classes from "./LoginForm.module.scss";

const LoginForm = () => {
  const {language, country} = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data.login.success) {
        dispatch(
          authActions.login({
            token: data?.login?.token,
            user: data?.login?.user,
          })
        );
        navigate("/welcome");
      } else {
        setError(data.login.message);
      }
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [aId, setAId] = useState("");
  const [email, setEmail] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: country === "EG" ? "20" : "966",
    number: "",
  });
  const [loginType, setLoginType] = useState(1);

  const loginHandler = (e) => {
    e.preventDefault();
    // debugger

    // if (!phoneNumberIsValid(phoneNumber) && loginType === 2) {
    //   return setError(words.INVALID_PHONE_NUMBER[language]);
    // }
 
    login({
      variables: { 
        login: {
          aId: loginType === 1 ? aId.toLowerCase() : null,
          email: loginType === 3 ? email.toLowerCase() : null,
          nationalId: loginType === 4 ? nationalId.toLowerCase() : null,
          phoneNumber:
            loginType === 2 ? formatPhoneNumber(phoneNumber.number) : null,
          countryCode: loginType === 2 ? phoneNumber.countryCode : null,
        },
        password: password,
        app: "WEB",
        language: language,
        firebaseToken: "",
      },
    });
  };

  useEffect(() => {
    if (aId && password && loginType === 1) {
      setDisableBtn(false);
    } else if (email && password && loginType === 3) {
      setDisableBtn(false);
    } else if (nationalId && password && loginType === 4) {
      setDisableBtn(false);
    } else if (
      phoneNumber.countryCode &&
      phoneNumber.number &&
      password &&
      loginType === 2
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [loginType, aId, email, nationalId, password, phoneNumber]);

  let passwordIconContent = passwordVisibility ? (
    <AiOutlineEye
      onClick={() => setPasswordVisibility(!passwordVisibility)}
      className={classes.passwordIcon}
    />
  ) : (
    <AiOutlineEyeInvisible
      onClick={() => setPasswordVisibility(!passwordVisibility)}
      className={classes.passwordIcon}
    />
  );

  return (
    <form className={classes.loginForm}>
      {error && <p style={{ color: "var(--errorColor)" }}>{error}</p>}

      <div className={classes.options}>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="aIdRadio"
            id="aIdRadio"
            checked={loginType === 1}
            value={loginType}
            onChange={() => setLoginType(1)}
          />
          <label className="form-check-label px-2" htmlFor="aIdRadio">
            {words.AID[language]}
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="phoneNumberRadio"
            id="phoneNumberRadio"
            checked={loginType === 2}
            value={loginType}
            onChange={() => setLoginType(2)}
          />
          <label className="form-check-label px-2" htmlFor="phoneNumberRadio">
            {words.PHONE[language]}
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="emailRadio"
            id="emailRadio"
            checked={loginType === 3}
            value={loginType}
            onChange={() => setLoginType(3)}
          />
          <label className="form-check-label px-2" htmlFor="emailRadio">
            {words.EMAIL[language]}
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="nationalIdRadio"
            id="nationalIdRadio"
            checked={loginType === 4}
            value={loginType}
            onChange={() => setLoginType(4)}
          />
          <label className="form-check-label px-2" htmlFor="nationalIdRadio">
            {country === "SA" ? words.NID_IQAMA_ID[language] : words.NID_PPN[language]}
          </label>
        </div>
      </div>

      <div className={classes.divContainer}>
        {loginType === 1 && (
          <Input
            placeholder={words.AID[language]}
            name="aId"
            required={true}
            value={aId}
            onChange={(e) => {
              setAId(e.target.value);
              setEmail("");
              setNationalId("");
              setPhoneNumber({ countryCode: "", number: "" });
            }}
          />
        )}
        {loginType === 2 && (
          <PhoneNumberInput
            country={country === "EG" ? "eg" : "sa"}
            placeholder={country === "SA" ? words.GAWAL_NUMBER[language] : words.PHONE_NUMBER[language]}
            name="phoneNumber"
            required={true}
            value={phoneNumber}
            onChange={(code, number) => {
              setPhoneNumber({ countryCode: code, number: number });
              setAId("");
              setEmail("");
              setNationalId("");
            }}
          />
        )}
        {loginType === 3 && (
          <Input
            placeholder={words.EMAIL[language]}
            name="email"
            required={true}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setAId("");
              setNationalId("");
              setPhoneNumber({ countryCode: "", number: "" });
            }}
          />
        )}
        {loginType === 4 && (
          <Input
            placeholder={country === "SA" ? words.NID_IQAMA_ID[language] : words.NID_PPN[language]}
            name="nationalId"
            required={true}
            value={nationalId}
            onChange={(e) => {
              setNationalId(e.target.value);
              setAId("");
              setEmail("");
              setPhoneNumber({ countryCode: "", number: "" });
            }}
          />
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {password && passwordIconContent}

        <Input
          type={passwordVisibility ? "text" : "password"}
          placeholder={words.PASSWORD[language]}
          name="password"
          autoComplete={password}
          required={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className={classes.extraInfo}>
        {/* <span>
          <input
            className="form-check-input mr-0"
            type="checkbox"
            id="rememberMe"
          // onChange={() => setRemeberMe(!remeberMe)}
          />
          <label className="form-check-label text-muted" htmlFor="rememberMe">
            {words.REMEMBER_ME[language]}
          </label>
        </span> */}

        <span className={classes.forgetPassword}>
          <Link to="forgot-password">{words.FORGET_PASSWORD[language]}</Link>
        </span>
      </div>

      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          disabled={loading || disableBtn}
          onClick={loginHandler}
          loading={loading}
        >
          {words.LOG_IN[language]}
        </Button>
      </div>
      <p className={classes.signup}>
        <span className="noSelect">{words.DONT_HAVE_ACCOUNT[language]} </span>
        <Link to="/auth/sign-up">{words.SIGNUP_ACCOUNT[language]}</Link>
      </p>
    </form>
  );
};

export default LoginForm;
