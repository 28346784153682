import moment from "moment";

export const selectInput = (array, key, value, isFixed) => {
  let newArray = [];
  array.map((item) => {
    const x = {
      value: item[key],
      label: item[value],
      isFixed: item[isFixed] ? item[isFixed] : false,
    };
    return newArray.push(x);
  });

  return newArray;
};

export const selectValueFormat = (value) => {
  if (!value) return null;
  return { value: value, label: value };
};

export const formatAId = (aId) => {
  return aId.match(/.{1,3}/g).join("-");
};

export const uppercaseFirstLetter = (input) => {
  const formatedInput = input.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return formatedInput;
}

export const formatPhoneNumber = (number) => {
  let formatedNumber = number;
  if (formatedNumber.startsWith("0")) {
    formatedNumber = number.replace("0", "");
    return formatedNumber
  }
  else {
    return formatedNumber
  }
};
export const passportNumberIsValid = (passportNumber) => {
  let regex = new RegExp(/^[0-9a-zA-Z\s\-\/]{6,20}$/);
  return regex.test(passportNumber);
}


export const phoneNumberIsValid = ({ countryCode, number }) => {
  let regex = new RegExp(/^[+]{1}(?:[0-9\-\(\)\/\.]\s?){9,15}[0-9]{1}$/);
  return regex.test(countryCode + number);
}

export const cutWords = (word) => {
  if (word && word.length > 10) {
    return word.slice(0, 10) + "...";
  } else {
    return word;
  }
};

export const emailIsInvalid = (email) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email !== null && email.match(emailRegex) === null) {
    return true;
  } else {
    return false;
  }
};

export const formatDate = (inputDate) => {
  if (!inputDate) return "";
  const date = new Date(inputDate);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedDate = dd + "/" + mm + "/" + yyyy;
  return formattedDate;
};

export const longDateformat = (inputDate) => {
  const date = new Date(inputDate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + " " + monthNames[monthIndex] + " " + year;
};
export const renderTodayOrYesterday = (date) => {
  const currentDate = new Date();
  const givenDate = new Date(date);
  const isToday =
    givenDate.getDate() === currentDate.getDate() &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getFullYear() === currentDate.getFullYear();

  const isYesterday =
    givenDate.getDate() === currentDate.getDate() - 1 &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getFullYear() === currentDate.getFullYear();

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  }
  const formatDate = (date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  if (isToday) {
    return <span> Today, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} </span>;
  } else if (isYesterday) {
    return <span> Yesterday, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>;
  } else {
    return <span>{formatDate(givenDate)} {","} {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
    </span>;
  }
}

export const formatDateToAPI = (inputDate) => {
  if (!inputDate) return "";
  let [d, m, y] = inputDate.split("/");
  if (+d < 10) d = "0" + +d;
  if (+m < 10) m = "0" + +m;
  return `${y}-${m}-${d}`;
};

export const formatDateFromAPI = (inputDate) => {
  if (!inputDate) return "";
  let [y, m, d] = inputDate.split("-");
  if (+d < 10) d = "0" + +d;
  if (+m < 10) m = "0" + +m;
  return `${d}/${m}/${y}`;
};

export const valueNull = (value) => {
  if (!value) return "-";
  else return value;
};

export const formatTime = (inputDate) => {
  const time = new Date(inputDate).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
    timeZone: "UTC",
  });
  return time;
};


export const shortenString = (str, maxLength) => {
  if (str.length >= maxLength) {
    let result = str.slice(0, maxLength) + "..."
    return result
  }
  return str

}

export const shortenDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return time;
};
export const truncateUrl = (url) => {
  const maxLength = 20;
  if (url.length <= maxLength) {
    return url;
  }
  const truncatedUrl = url.slice(0, maxLength) + "...";
  return truncatedUrl;
};


// Number.prototype.padLeft = function (base, chr) {
//   var len = (String(base || 10).length - String(this).length) + 1;
//   return len > 0 ? new Array(len).join(chr || '0') + this : this;
// }

// export const formatDateAndTime = (inputDate) => {
//   const d = new Date(inputDate);
//   const dformat  = [(d.getMonth() + 1).padLeft(),
//   d.getDate().padLeft(),
//   d.getFullYear()].join('/') + ' ' +
//     [d.getHours().padLeft(),
//     d.getMinutes().padLeft()].join(':');

//   return dformat;
// }


export const formatDateAndTime = (inputDate) => {
  var newDate = new Date(inputDate);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  }
  else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return sDay + "/" + sMonth + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
  return (value < 10) ? "0" + value : value;
}

export const formatAMPM = (inputDate) => {
  const date = new Date(inputDate);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  const time = date.toLocaleString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
  return time
}

export const compareDates = (date, timeSlot) => {
  const dateParts = date.split("/");
  let mainDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  let todaysDate = new Date();
  if (mainDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    let inputDate = new Date(timeSlot)
    inputDate.setMinutes(inputDate.getMinutes() + inputDate.getTimezoneOffset())
    const inputTime = inputDate.toLocaleString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
    const currentTime = new Date().toLocaleString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });

    if (inputTime <= currentTime) {
      return true
    }
    else {
      return false
    }
  }
  else {
    return false
  }
}

//[{label: 1, value: 1} ]==> [1]
export const convertSelectIntoArr = (arr) => {
  return arr?.map((obj) => {
    return obj?.value;
  });
};

//[1] ==> [{label: 1, value: 1}]
export const convertArrIntoSelect = (arr) => {
  return arr?.map((obj) => {
    return { label: obj, value: obj };
  });
};


export const dateDiffInDays = (date1, date2) => {
  // Convert both dates to UTC
  const [day1, month1, year1] = date1.split('/');
  const [day2, month2, year2] = date2.split('/');

  // Create Date objects using the parsed values (subtract 1 from month since it's zero-based)
  const utcDate1 = new Date(Date.UTC(year1, month1 - 1, day1));
  const utcDate2 = new Date(Date.UTC(year2, month2 - 1, day2));

  // Calculate the difference in milliseconds
  const diffInMs = utcDate2 - utcDate1;

  // Convert the difference from milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
}

export const calcDiffrenceTime = (time1, time2) => {
  const time1Arr = time1.split(":");
  const time2Arr = time2.split(":");
  const time1Hours = time1Arr[0];
  const time1Minutes = time1Arr[1];
  const time2Hours = time2Arr[0];
  const time2Minutes = time2Arr[1];
  const time1InMinutes = parseInt(time1Hours) * 60 + parseInt(time1Minutes);
  const time2InMinutes = parseInt(time2Hours) * 60 + parseInt(time2Minutes);
  const diffrence = time2InMinutes - time1InMinutes;
  return diffrence;
}

export const validateNationalId = (countryOfCitizenship,
  dateOfBirth,
  gender,
  nationalId
) => {
  const possibleDateFormats = [
    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",
    "YYYY-DD-MM",
  ];

  let formattedDateOfBirth = null;

  // Check if the dateOfBirth matches the desired format before attempting to parse it
  if (moment(dateOfBirth, "YYYY-MM-DD", true).isValid()) {
    formattedDateOfBirth = moment(dateOfBirth);
  } else {
    for (const format of possibleDateFormats) {
      formattedDateOfBirth = moment(dateOfBirth, format);
      if (formattedDateOfBirth.isValid()) {
        break; // Successfully parsed the date, exit the loop
      }
    }
  }

  if (!formattedDateOfBirth.isValid()) {
    return false;
  }

  const year = formattedDateOfBirth.format("YYYY");
  const month = formattedDateOfBirth.format("MM");
  const day = formattedDateOfBirth.format("DD");
  if (countryOfCitizenship !== "EG") {
    return false;
  }

  if (!nationalId) {
    return false;
  }

  if (nationalId.length !== 14) {
    return false;
  }

  if (nationalId.charAt(0) !== "2" && nationalId.charAt(0) !== "3") {
    return false;
  }

  if (
    (gender === "FEMALE" && nationalId.charAt(12) % 2 !== 0) ||
    (gender === "MALE" && nationalId.charAt(12) % 2 !== 1)
  ) {
    return false;
  }
  if (
    (nationalId.charAt(0) === "2" && year.charAt(0) !== "1") ||
    (nationalId.charAt(0) === "3" && year.charAt(0) !== "2")
  ) {
    return false;
  }

  if (
    nationalId.substring(1, 3) !== year.substring(2, 4) ||
    nationalId.substring(3, 5) !== month ||
    nationalId.substring(5, 7) !== day
  ) {
    return false;
  }
  return true;
}