import { useDispatch, useSelector } from "react-redux";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SelectLanguage from "../../components/layout/SelectLanguage";
import ProfileSettings from "../../components/layout/ProfileSettings";
import ayamedicaIcon from "../../assets/images/AyaMedica-Login.png";
import ayamedicaPanal from "../../assets/images/ayamedicaPanal.jpg";
import { authActions } from "../../store";
import classes from "./AuthLayout.module.scss";

const AuthLayout = () => {
  const { language, user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    if (pathname === "/auth") {
      dispatch(authActions.logout())
    }
    navigate("..");
  };

  return (
    <section className={classes.section}>
      <div
        className={classes.imagePannel}
        style={{
          height:
            pathname === "/welcome/new" || pathname === "/auth/sign-up"
              ? "auto"
              : "100vh",
        }}
      >
        <img src={ayamedicaPanal} alt="Ayamedica Panal" />
      </div>

      <div className={classes.content}>
        <div className={classes.selectLanguage}>
          <div>
            {pathname === "/auth/forgot-password" || pathname === "/auth" && (
              <>
                {language === "ar" ? (
                  <BiRightArrowAlt size={35} onClick={goBack} />
                ) : (
                  <BiLeftArrowAlt size={35} onClick={goBack} />
                )}
              </>
            )}
          </div>

          <div className={classes.userData}>
            <SelectLanguage language={language} />

            {user && <ProfileSettings />}
          </div>
        </div>

        <main>
          <div className={classes.image}>
            <img src={ayamedicaIcon} alt="Ayamedica Logo" />
          </div>
          <Outlet />
        </main>
      </div>
    </section>
  );
};

export default AuthLayout;
