import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../graphql/auth/Mutations';
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FiLogOut } from "react-icons/fi";
import words from '../../assets/words';
import classes from './ProfileSettings.module.scss';

const ProfileSettings = () => {
    const { language, generalInfo, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logout] = useMutation(LOGOUT, {
        onCompleted: (data) => {
            // debugger
            if (data.logOut.success) {
                dispatch(authActions.logout());
                navigate('/');
            }
        }
    });

    const logoutHandler = () => {
        logout();
    }

    return (
        <div className={classes.container}>

            <NavDropdown
                className={classes.dropdown}
                title={
                    <div>
                        {generalInfo?.profilePictureBaseURL && user?.profilePictureUrl && <img
                            src={generalInfo?.profilePictureBaseURL + user?.profilePictureUrl}
                            alt="User"
                            className="avatar"
                            style={{ width: "70px" }}
                        />}
                    </div>
                }>
                <Dropdown.Item onClick={logoutHandler}>
                    <FiLogOut size={18} /> {words.LOG_OUT[language]}
                </Dropdown.Item>
            </NavDropdown>

        </div>
    )
}

export default ProfileSettings;