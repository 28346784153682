import { useState } from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import classes from './PhoneNumberInput.module.scss';

const PhoneNumberInput = ({
    name,
    placeholder,
    value,
    style,
    className,
    required = false,
    disabled = false,
    onChange = (code, number) => null,
    onKeyPress = () => null,
    country = 'eg'
}) => {
    const language = useSelector((state) => state.auth.language);
    const [isActive, setIsActive] = useState(true);

    return (
        <div className={`${classes.container} ${className}`} style={style}>
            <label className={isActive && classes.inputFocus} htmlFor={name} style={language === 'ar' ? { right: "50px" } : null}>
                {placeholder} {required && <span style={{ color: "var(--errorColor)", textDecoration: "none" }}> *</span>}
            </label>
            <PhoneInput
            disabled={disabled}
                country={country}
                value={value.countryCode + value.number}
                inputProps={{
                    name: { name },
                    required: true,
                }}
                defaultMask="... ... ....."
                masks={{ eg: '... ... .....', us: '... ... .....' }}
                autoFormat={true}
                placeholder=""
                countryCodeEditable={false}
                onChange={(value, country, e, formattedValue) => {
                    const x = (formattedValue).replace('+', '').replace(/ /g, '').slice(country.dialCode.length);
                    onChange("+" + country.dialCode, x)
                }}
                buttonClass={language === 'ar' ? classes.phoneButtonAr : classes.phoneButton}
                inputClass={language === 'ar' ? classes.phoneNumberAr : classes.phoneNumber}
                dropdownClass={classes.codesList}
                onFocus={(e, country) => setIsActive(true)}
                // excludeCountries={country === 'eg' ? ['sa'] : ['eg']}
                />
        </div>
    )
}

export default PhoneNumberInput;