import { redirect } from "react-router-dom";

export function loader() {
  // debugger
  const country = sessionStorage.getItem("country");
  const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];
  const incomingCountry = document.location.search.split('?')[2]?.split('country=')[1];

  const token = sessionStorage.getItem("token");
  const pathname = window.location.pathname;

  if ((country || incomingCountry) && !token && !incomingToken && pathname !== "/auth" && pathname !== "/") {
    return redirect("/auth");
  }
  else {
    return null;
  }
}

export function tokenLoader() {
  // debugger
  const country = sessionStorage.getItem("country");
  const token = sessionStorage.getItem("token");
  const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];
  const pathname = window.location.pathname;
  // debugger
  if ((token || incomingToken) && country && pathname === "/auth") {
    return redirect("/welcome");
  }
  else if ((!token && !incomingToken) && (!country || country === 'undefined') && pathname === "/auth") {
    return redirect("/");
  }
  else {
    return null;
  }
}

export function languageLoader() {
  let language = sessionStorage.getItem("language");

 
  // const goToWelcome = document.location.search.split('?')[3]?.split("=")[1];
  // console.log(goToWelcome)
  // if(goToWelcome) {
  //   return redirect("/welcome")
  // }

  const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];
  const incomingCountry = document.location.search.split('?')[2]?.split('country=')[1];
  if ((incomingToken && incomingToken !== "undefined") && (incomingCountry && incomingCountry !== 'undefined')) {
    console.log("resr")
    // return redirect("/welcome");
  }

  else if (!language) {
    sessionStorage.setItem("language", "en");
    language = "en";
  }
  // debugger
  return language;
}

export function plansLoader() {
  const token = sessionStorage.getItem("token");
  const pathname = window.location.pathname;
  // debugger
  if (!token && pathname === '/plans') {
    return redirect("/auth")
  }
  else {
    return null;
  }
}