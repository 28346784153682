import classes from "./PhoneDisplay.module.scss"
import ayamedica from "../../assets/images/new-logo.png"

const PhoneDisplay = () => {
    return(
        <div className={classes.content}>
            <img src={ayamedica} alt="logo pic"/>
            <h2>Our Ayamedica web application doesn't support mobile view until now</h2>
            <p>Try to open from <span>Mac</span> or <span>iPad</span></p>
        </div>
    )
}

export default PhoneDisplay