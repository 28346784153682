const words = {
  //*************LOGIN PAGE*************//
  JUST_NOW: {
    ar: "الآن",
    en: "just now",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ROLE: {
    ar: "الدور",
    en: "Role",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  DAYS_LEFT_OF_TRAIL: {
    ar: "الأيام المتبقية من الفترة التجريبية المجانية",
    en: "Days left of the free trial",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FREE_TRAIL_EXPIRED: {
    ar: "انتهت صلاحية الفترة التجريبية المجانية الخاصة بك ، يرجى الاتصال بالدعم",
    en: "Your Free Trial Expired, Please Contact Support",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PACKAGE_EXPIRED: {
    ar: "انتهت صلاحية الباقة الخاصة بك ، يرجى الاتصال بالدعم",
    en: "Your Package Expired, Please Contact Support",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FREE_TRAIL: {
    ar: "فترة تجريبية مجانية",
    en: "Free Trial",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CANCEL_TRAIL: {
    ar: "إلغاء الفترة التجريبية المجانية",
    en: "Cancel Free Trial",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  LANGUAGE: {
    ar: "اللغة",
    en: "Language",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FROM: {
    ar: "من",
    en: "From",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TO: {
    ar: "إلى",
    en: "To",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ADD: {
    ar: "إضافة",
    en: "Add",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NO_OPTIONS: {
    ar: "لا يوجد خيارات",
    en: "No Options",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NO_MORE_OPTIONS: {
    ar: "لا مزيد من الخيارات",
    en: "No More Options",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  LOADING: {
    ar: "جاري التحميل...",
    en: "Loading...",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CORRECT: {
    ar: "صحيح",
    en: "Correct",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ERR: {
    ar: "خطأ",
    en: "Error",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ERROR: {
    ar: "تم حدوث خطأ!",
    en: "An Error Occurred!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CONNECTION_ERROR: {
    ar: "خطأ في الاتصال!",
    en: "Connection Error!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHECK_YOUR_NETWORK: {
    ar: "يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.",
    en: "Please check your internet connection and try again.",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ERROR_MESSAGE: {
    ar: "برجاء التواصل بالدعم",
    en: "Please Contact Support",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  LOG_IN: {
    ar: "تسجيل الدخول",
    en: "Login",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SIGN_IN: {
    ar: "تسجيل الدخول",
    en: "Sign In",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SELECT_COUNTRY: {
    ar: "اختر البلد",
    en: "Select Country",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SELECT_COUNTRY_PARAGRAPGH: {
    ar: "اختر بلدك حتى نتمكن من تزويدك بأفضل تجربة",
    en: "Select your country that we can provide you with best experience",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_YOUR_DETAILS: {
    ar: "أدخل بياناتك",
    en: "Please enter your details",
    tu: "Lütfen bilgilerinizi girin",
    ru: "Пожалуйста, введите свои данные",
    fr: "Veuillez entrer vos données",
  },
  LOGIN_ID: {
    en: "A-ID or National ID Number or Phone Number",
    ar: "رقم المستخدم أو الرقم القومي او رقم الهاتف",
    tu: "A-ID veya Ulusal Kimlik Numarası",
    ru: "A-ID или национальный идентификационный номер",
    fr: "A-ID ou numéro de carte d'identité nationale",
  },
  LOGIN_MESSAGE: {
    en: "You have to be an Ayamedica member in order to sign up for Organization, Hospital, Clinc or School.",
    ar: "يجب أن تكون عضوا في Ayamedica لتتمكن من التسجيل في المنظمة، المستشفى، العيادة أو المدرسة.",
    tu: "Ayamedica üyesi olmanız gerekiyor, kuruluş, hastane, klinik veya okul için kayıt olmak için.",
    ru: "Вы должны быть членом Ayamedica, чтобы зарегистрироваться в организации, больнице, клинике или школе.",
    fr: "Vous devez être membre d'Ayamedica pour vous inscrire à une organisation, un hôpital, un cabinet médical ou une école.",
  },
  INCORECT_ID_PASSWORD: {
    en: "incorrect a-id or password!!",
    ar: "رقم المستخدم أو كلمة المرور غير صحيحة",
    tu: "yanlış a-id veya şifre!!",
    ru: "неверный идентификатор или пароль!!",
    fr: "identifiant ou mot de passe incorrect!!",
  },
  NOT_A_MEMBER: {
    en: "Not a member in Ayamedica?",
    ar: "لست عضوا في أياميديكا؟",
    tu: "yanlış a-id veya şifre!!",
    ru: "неверный идентификатор или пароль!!",
    fr: "identifiant ou mot de passe incorrect!!",
  },
  DOwNLOAD_FROM_HERE: {
    en: "Download from here",
    ar: "تحميل من هنا",
    tu: "yanlış a-id veya şifre!!",
    ru: "неверный идентификатор или пароль!!",
    fr: "identifiant ou mot de passe incorrect!!",
  },
  //*************SignUp PAGE*************//
  ALEARDY_HAVE: {
    en: "Already have",
    ar: "لديك بالفعل",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  THE_RIGHT_PLAN: {
    en: "The Right Plan For Your Business",
    ar: "الخطة المناسبة لعملك",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  WE_HAVE_POWERFUL_PLANS: {
    en: "We have powerful plans to Showcase your business and get discovered as a creative entrepreneurs. Every thing you need.",
    ar: "لدينا خطط قوية لعرض أعمالك واكتشافها كأحد رواد الأعمال المبدعين. كل ما تحتاجه",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  BRONZE: {
    en: "Bronze",
    ar: "",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  SILVER: {
    en: "Silver",
    ar: "",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  GOLD: {
    en: "Gold",
    ar: "",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  PLATINIUM: {
    en: "Platinium",
    ar: "",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  START_FREE_TRIAL: {
    en: "Start Free Trial",
    ar: "ابدأ الإصدار التجريبي المجاني",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  CONTACT_US_FOR_SUBSCRIPTION: {
    en: "Contact us for subscription",
    ar: "اتصل بنا للاشتراك",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  PAY_NOW: {
    en: "Pay Now",
    ar: "أدفع الان",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  SUBSCRIBE: {
    en: "Subscribe",
    ar: "اشترك",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  FREE_TRIAL_TITLE: {
    ar: "بدأ 14 يوم تجربة مجانية",
    en: "Start 14 days Trial",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FREE_TRAIL_MESSAGE: {
    ar: "انت على نسخة التجريبية المجانية",
    en: "You are on a free trial version",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  BY_SIGNING_IN_YOU_AGREE_TO_OUR: {
    en: "By signing in you agree to our",
    ar: "بالتسجيل فإنك توافق على",
    tu: "Giriş yaparak, şunları kabul ediyorsunuz",
    ru: "Войдя, вы соглашаетесь с нашими",
    fr: "En vous connectant, vous acceptez notre",
  },
  NATIONAL_ID: {
    en: "National ID",
    ar: "الرقم القومي",
    tu: "Ulusal Kimlik Numarası",
    ru: "Национальный идентификационный номер",
    fr: "Numéro de carte d'identité nationale",
  },
  ID_NUMBER: {
    en: "National ID",
    ar: "رقم الهوية",
    tu: "Kimlik Numarası",
    ru: "Идентификационный номер",
    fr: "Numéro d'identification",
  },
  IQAMA_ID: {
    en: "Iqama ID",
    ar: "رقم الإقامة",
    tu: "İkame Kimliği",
    ru: "Икама ID",
    fr: "Identifiant Iqama",
  },
  NATIONAL_ID_NUMBER: {
    en: "National ID",
    ar: "الرقم القومي",
    tu: "Ulusal Kimlik Numarası",
    ru: "Национальный идентификационный номер",
    fr: "Numéro de carte d'identité nationale",
  },
  NID_PPN: {
    en: "NID/PPN",
    ar: "الرقم القومي او رقم الباسبور",
    tu: "NID/PPN",
    ru: "NID/PPN",
    fr: "NID/PPN",
  },
  NID_IQAMA_ID: {
    en: "NID/IQAMAID",
    ar: "رقم الهوية او رقم الإقامة",
    tu: "NID/Iqama ID",
    ru: "NID/Iqama ID",
    fr: "NID/Iqama ID",
  },
  PASSPORT_ID: {
    en: "Passport ID Number",
    ar: "رقم الباسبور",
    tu: "Ulusal Kimlik Numarası",
    ru: "Национальный идентификационный номер",
    fr: "Numéro de carte d'identité nationale",
  },
  PERSONAL_ACCOUNT: {
    en: "Personal Account",
    ar: "حساب شخصي",
    tu: "Kişisel hesap",
    ru: "Личный аккаунт",
    fr: "Compte personnel",
  },
  CREATE_ACCOUNT_FOR_YOUR_ORGANIZATION: {
    en: "Create an account for your organization",
    ar: "إنشاء حساب لمؤسستك",
    tu: "Bir kuruluşunuz için bir hesap oluşturun",
    ru: "Создать учетную запись для вашей организации",
    fr: "Créer un compte pour votre organisation",
  },
  SELECT_GOVERNORATE_FIRST: {
    en: "Select Governorate First",
    ar: "اختر المحافظة أولا",
    tu: "Önce Valiliği Seçin",
    ru: "Сначала выберите губернаторство",
    fr: "Sélectionnez d'abord le gouvernorat",
  },
  DONT_HAVE_ACCOUNT: {
    en: "Don’t have an account?",
    ar: "ليس لديك حساب؟",
    tu: "Hesabınız yok mu?",
    ru: "У вас нет аккаунта?",
    fr: "Vous n'avez pas de compte ?",
  },
  MUST_BE_AYAMEDICA_MEMBER: {
    en: "You have to be an Ayamedica member in order to sign up for Organization, Hospital, Clinc or School.",
    ar: "يجب أن تكون عضوًا في Ayamedica من أجل التسجيل في منظمة أو مستشفى أو عيادة أو مدرسة.",
    tu: "Organizasyon, Hastane, Klinik veya Okula kayıt olabilmeniz için Ayamedica üyesi olmanız gerekmektedir.",
    ru: "Вы должны быть участником Ayamedica, чтобы зарегистрироваться в организации, больнице, клинике или школе.",
    fr: "Vous devez être membre Ayamedica pour vous inscrire à une organisation, un hôpital, une clinique ou une école.",
  },
  HAVE_ACCOUNT: {
    en: "Do you have an account?",
    ar: "هل لديك حساب؟",
    tu: "Hesabın var mı?",
    ru: "У тебя есть аккаунт?",
    fr: "Avez-vous un compte?",
  },
  SIGNUP_ACCOUNT: {
    en: "Sign Up",
    ar: "تسجيل حساب",
    tu: "Üye olmak",
    ru: "Зарегистрироваться",
    fr: "S'inscrire",
  },

  //*************GLOBAL WORDS*************//
  LOG_OUT: {
    ar: "تسجيل الخروج",
    en: "Log Out",
    tu: "Çıkış Yap",
    ru: "Выйти",
    fr: "Se déconnecter",
  },
  CHANGE_PASSWORD: {
    ar: "تغيير كلمة المرور",
    en: "Change Password",
    tu: "Şifre Değiştir",
    ru: "Сменить пароль",
    fr: "Changer le mot de passe",
  },
  CHANGE: {
    ar: "تغيير",
    en: "Change",
    tu: "Şifre Değiştir",
    ru: "Сменить пароль",
    fr: "Changer le mot de passe",
  },
  CHANGED_PASSWORD_SUCCESSFYLLY: {
    ar: "تم تغيير كلمة المرور بنجاح",
    en: "Your Password has been changed successfully",
    tu: "Şifre Değiştir",
    ru: "Сменить пароль",
    fr: "Changer le mot de passe",
  },
  RESET_PASSWORD: {
    ar: "إعادة تعيين كلمة المرور",
    en: "Reset Password",
    tu: "Şifreyi Sıfırla",
    ru: "Сбросить пароль",
    fr: "Réinitialiser le mot de passe",
  },
  WELCOME_BACK: {
    ar: "مرحبا بعودتك!",
    en: "Welcome back!",
    tu: "Tekrar hoşgeldiniz!",
    ru: "Добро пожаловать обратно!",
    fr: "Bienvenue à nouveau!",
  },
  WELCOME: {
    ar: "مرحبا!",
    en: "Welcome!",
    tu: "Hoş geldin!",
    ru: "Добро пожаловать!",
    fr: "Bienvenue!",
  },
  REMEMBER_ME: {
    ar: "تذكرني",
    en: "Remember me",
    tu: "Beni hatırla",
    ru: "Запомни меня",
    fr: "Souviens-toi de moi",
  },
  FORGET_PASSWORD: {
    ar: "نسيت كلمة المرور ؟",
    en: "Forgot Password ?",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  EDIT_PHONE_NUMBER: {
    ar: "نسيت كلمة المرور ؟",
    en: "Edit Phone Number",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  ENTER_NEW_PHONE_NUMBER: {
    ar: "نسيت كلمة المرور ؟",
    en: "Please, Enter Your Phone Number To Receive A Verification Code",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  RESEND_CODE: {
    ar: "اعادة ارسال الكود",
    en: "Resend Code",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  MINIMUM_8_CHARACTERS: {
    ar: "الحد الأدنى 8 أحرف",
    en: "Minimum 8 characters",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_LOWERCASE: {
    ar: "حرف صغير واحد على الأقل (a-z)",
    en: "Has At Least 1 Lowercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_UPPERCASE: {
    ar: "حرف كبير واحد على الأقل (a-z)",
    en: "Has At Least 1 Uppercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_NUMBER: {
    ar: "رقم واحد على الأقل (0-9)",
    en: "Has At Least 1 Number (0-9)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  PASSWORDS_DO_NOT_MATCH: {
    ar: "كلمات المرور غير متطابقة",
    en: "The passwords do not match",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  CONTINUE: {
    ar: "متابعة",
    en: "Continue",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  PASSWORD: {
    en: "Password",
    ar: "كلمة المرور",
    tu: "Şifre",
    ru: "Пароль",
    fr: "Mot de passe",
  },
  RE_PASSWORD: {
    en: "Re-Password",
    ar: "إعادة كلمة المرور",
    tu: "Şifreyi tekrar girin",
    ru: "Повторите пароль",
    fr: "Répéter le mot de passe",
  },
  NAME: {
    en: "Name",
    ar: "الاسم",
    tu: "İsim",
    ru: "Имя",
    fr: "Nom",
  },
  AID: {
    en: "AID",
    ar: "رقم المستخدم",
    tu: "A-ID",
    ru: "Идентификатор",
    fr: "Identifiant",
  },
  SPECIALITY: {
    en: "Speciality",
    ar: "التخصص",
    tu: "uzmanlık",
    ru: "Специальность",
    fr: "Spécialité",
  },
  CLINIC_NAME: {
    en: "Clinic's Name",
    ar: "اسم العيادة",
    tu: "Klinik Adı",
    ru: "Название клиники",
    fr: "Nom de la clinique",
  },
  GRADE: {
    en: "Grade",
    ar: "المرحلة",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  GRADES: {
    en: "Grades",
    ar: "المراحل",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  NEXT_GRADE: {
    en: "Next Grade",
    ar: "المرحلة التالية",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  PREVIOUS_GRADE: {
    en: "Previous Grade",
    ar: "المرحلة السابقة",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  FINISH: {
    en: "Finish",
    ar: "إنهاء",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  NEW_CLASS_HAS_BEEN_CREATED: {
    en: "Your Class Has Been Created",
    ar: "تم إنشاء الفصل الجديد",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  CLASS_HAS_BEEN_EDITED: {
    en: "Your Class Has Been Edited Successfully",
    ar: "تم تعديل الفصل بنجاح",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  DELETE_CLASS_CONFIRMATION: {
    en: "Are You Sure You Want To Delete The Class?",
    ar: "هل انت متأكد من حذف الفصل؟",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  DELETE_CLASS_SUCCESS: {
    en: "Class Deleted Successfully",
    ar: "تم حذف الفصل بنجاح",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  DELETE_CLASS_ERROR: {
    en: "You Have To Delete All Students in This Class Before Deleting It",
    ar: "يجب حذف جميع الطلاب في هذا الفصل قبل حذفه",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  NEW_CLASSES_HAS_BEEN_CREATED: {
    en: "Your Classes Have Been Created",
    ar: "تم إنشاء فصول جديدة",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  CLASS: {
    en: "Class",
    ar: "الصف",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  CLASS_NAME: {
    en: "Class Name",
    ar: "اسم الصف",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  CLASS_CAPACITY: {
    en: "Class Capacity",
    ar: "سعة الصف",
    tu: "Sınıf",
    ru: "Класс",
    fr: "Classe",
  },
  TIME: {
    en: "Time",
    ar: "الوقت",
    tu: "Zaman",
    ru: "Время",
    fr: "Temps",
  },
  SIGN_UP: {
    en: "Add a New Organization",
    ar: "تسجيل",
    tu: "Kayıt Ol",
    ru: "Зарегистрироваться",
    fr: "S'inscrire",
  },
  OF_AYAMEDICA: {
    en: "of Ayamedica.",
    ar: "تبع Ayamedica.",
    tu: "Ayamedica'nın.",
    ru: "Аямедика.",
    fr: "de Ayamedica.",
  },
  PRIVACY_AND_POLICY: {
    en: "Privacy Policy",
    ar: "سياسة الخصوصية",
    tu: "Gizlilik Politikası",
    ru: "Политика конфиденциальности",
    fr: "Politique de confidentialité",
  },
  TERMS_AND_CONDITIONS: {
    en: "Terms & Conditions",
    ar: "الأحكام والشروط",
    tu: "Gizlilik Politikası",
    ru: "Политика конфиденциальности",
    fr: "Politique de confidentialité",
  },
  YES: {
    en: "Yes",
    ar: "نعم",
    tu: "Evet",
    ru: "Да",
    fr: "Oui",
  },
  NO: {
    en: "No",
    ar: "لا",
    tu: "Hayır",
    ru: "Нет",
    fr: "Non",
  },
  AND: {
    en: "and",
    ar: "و",
    tu: "Hayır",
    ru: "Нет",
    fr: "Et",
  },
  FULL_NAME: {
    en: "Full Name",
    ar: "الاسم الكامل",
    tu: "Ad Soyad",
    ru: "Полное имя",
    fr: "Nom complet",
  },
  EMAIL: {
    en: "Email",
    ar: "البريد الإلكتروني",
    tu: "E-posta",
    ru: "Электронная почта",
    fr: "Email",
  },
  INVALID_EMAIL: {
    en: "Invalid Email Format",
    ar: "البريد الإلكتروني غير صالح",
    tu: "E-posta",
    ru: "Электронная почта",
    fr: "Email",
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    ar: "رقم الهاتف",
    tu: "Telefon Numarası",
    ru: "Номер телефона",
    fr: "Numéro de téléphone",
  },
  GAWAL_NUMBER: {
    en: "Phone Number",
    ar: "رقم الجوال",
    tu: "Telefon Numarası",
    ru: "Номер телефона",
    fr: "Numéro de téléphone",
  },
  INVALID_PHONE_NUMBER: {
    en: "Invalid Phone Number",
    ar: "رقم الهاتف غير صالح",
    tu: "Telefon Numarası",
    ru: "Номер телефона",
    fr: "Numéro de téléphone",
  },
  INVALID_NATIONAL_ID: {
    en: "Invalid National ID",
    ar: "الرقم القومي غير صالح",
    tu: "Telefon Numarası",
    ru: "Номер телефона",
    fr: "Numéro de téléphone",
  },
  INVALID_PASSPORT_NUMBER: {
    en: "Invalid Passport Number",
    ar: "رقم الباسبور غير صالح",
    tu: "Telefon Numarası",
    ru: "Номер телефона",
    fr: "Numéro de téléphone",
  },
  CONFIRM_PASSWORD: {
    en: "Confirm Password",
    ar: "تأكيد كلمة المرور",
    tu: "Şifreyi Onayla",
    ru: "Подтвердите Пароль",
    fr: "Confirmer le mot de passe",
  },
  DISPLAY: {
    en: "Display",
    ar: "عرض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  DISPLAYED: {
    en: "Displayed",
    ar: "معروض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  OUT_OF: {
    en: "Out Of",
    ar: "من",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  NEXT: {
    en: "Next",
    ar: "التالي",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  PREVIOUS: {
    en: "Previous",
    ar: "السابق",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  ACCOUNT_TYPE: {
    en: "Account Type",
    ar: "نوع الحساب",
    tu: "Kurum",
    ru: "Организация",
    fr: "Organisation",
  },
  ORGANIZATION: {
    en: "Organization",
    ar: "المؤسسة",
    tu: "Kurum",
    ru: "Организация",
    fr: "Organisation",
  },
  EDUCATION_TYPE: {
    en: "Education Type",
    ar: "نوع التعليم",
    tu: "Kurum",
    ru: "Организация",
    fr: "Organisation",
  },
  HEADQUARTER: {
    en: "Headquarter",
    ar: "اسم المؤسسة",
    tu: "Kurum",
    ru: "Организация",
    fr: "Organisation",
  },
  PROVIDER: {
    en: "Provider",
    ar: "المزود",
    tu: "Sağlayıcı",
    ru: "Поставщик",
    fr: "Fournisseur",
  },
  ALEARDY_EXIST: {
    en: "Aleardy Exists",
    ar: "موجود مسبقا",
    tu: "Zaten Var",
    ru: "Уже существует",
    fr: "Déjà existant",
  },
  I_AGREE_TO: {
    en: "I accept the",
    ar: "أوافق على",
    tu: "Kabul ediyorum",
    ru: "Я согласен с",
    fr: "J'accepte",
  },
  AGREE: {
    en: "Agree",
    ar: "موافق",
    tu: "Kabul ediyorum",
    ru: "Я согласен с",
    fr: "J'accepte",
  },
  CLOSE: {
    en: "Close",
    ar: "غلق",
    tu: "Kabul ediyorum",
    ru: "Я согласен с",
    fr: "J'accepte",
  },
  BACK: {
    en: "Back",
    ar: "السابق",
    tu: "Geri",
    ru: "Назад",
    fr: "Retour",
  },
  TYPE: {
    en: "Type",
    ar: "نوع",
    tu: "Tipi",
    ru: "Тип",
    fr: "Type",
  },
  COUNTRY: {
    en: "Country",
    ar: "البلد",
    tu: "İl",
    ru: "Губерния",
    fr: "Gouvernorat",
  },
  GOVERNORATE: {
    en: "Governorate",
    ar: "المحافظة",
    tu: "İl",
    ru: "Губерния",
    fr: "Gouvernorat",
  },
  AREA: {
    en: "Area",
    ar: "المنطقة",
    tu: "Bölge",
    ru: "Область",
    fr: "Région",
  },
  STREET: {
    en: "Street",
    ar: "الشارع",
    tu: "Sokak",
    ru: "Улица",
    fr: "Rue",
  },
  CITY: {
    en: "City",
    ar: "المدينة",
    tu: "Şehir",
    ru: "Город",
    fr: "Ville",
  },
  DONE: {
    en: "Done",
    ar: "تم",
    tu: "Tamam",
    ru: "Готово",
    fr: "Terminé",
  },
  NOT_DONE: {
    en: "Not Done",
    ar: "لم يتم",
    tu: "Yapılmadı",
    ru: "Не сделано",
    fr: "Pas fait",
  },
  ENTER_REASON: {
    en: "Enter Reason",
    ar: "ادخل السبب",
    tu: "Yapılmadı",
    ru: "Не сделано",
    fr: "Pas fait",
  },
  FIRST_NAME: {
    en: "First Name",
    ar: "الاسم الاول",
    tu: "İsim",
    ru: "Имя",
    fr: "Prénom",
  },
  LAST_NAME: {
    en: "Last Name",
    ar: "الكنية",
    tu: "Soyadı",
    ru: "Фамилия",
    fr: "Nom de famille",
  },
  CARD_NUMBER: {
    en: "Card Number",
    ar: "الاسم الاول",
    tu: "İsim",
    ru: "Имя",
    fr: "Prénom",
  },
  CCV: {
    en: "CCV",
    ar: "الاسم الاول",
    tu: "İsim",
    ru: "Имя",
    fr: "Prénom",
  },
  EXPIRY_DATE: {
    en: "Expiry Date",
    ar: "الاسم الاول",
    tu: "İsim",
    ru: "Имя",
    fr: "Prénom",
  },
  BRANCH: {
    en: "Branch",
    ar: "فرع",
    tu: "Şube",
    ru: "Филиал",
    fr: "Branche",
  },
  BIRTH_DATE: {
    en: "Birth Date",
    ar: "تاريخ الميلاد",
    tu: "Doğum Tarihi",
    ru: "Дата рождения",
    fr: "Date de naissance",
  },
  CURRENT_PASSWORD: {
    en: "Current Password",
    ar: "كلمة المرور الحالية",
    tu: "Şu anki şifre",
    ru: "Текущий пароль",
    fr: "Mot de passe actuel",
  },
  ZIP_CODE: {
    en: "Zip Code",
    ar: "الرقم البريدى",
    tu: "Cinsiyet",
    ru: "Пол",
    fr: "Le genre",
  },
  GENDER: {
    en: "Gender",
    ar: "جنس",
    tu: "Cinsiyet",
    ru: "Пол",
    fr: "Le genre",
  },
  NEW_PASSWORD: {
    en: "New Password",
    ar: "كلمة السر الجديدة",
    tu: "Yeni Şifre",
    ru: "новый пароль",
    fr: "nouveau mot de passe",
  },
  POSTAL_CODE: {
    en: "Postal Code",
    ar: "الرمز البريدي",
    tu: "Posta Kodu",
    ru: "Почтовый индекс",
    fr: "Code postal",
  },
  WEIGHT: {
    en: "Weight",
    ar: "وزن",
    tu: "Ağırlık",
    ru: "Вес",
    fr: "Poids",
  },
  KG: {
    en: "Kg",
    ar: "كج",
    tu: "Ağırlık",
    ru: "Вес",
    fr: "Poids",
  },
  HEIGHT: {
    en: "Height",
    ar: "ارتفاع",
    tu: "Yükseklik",
    ru: "Высота",
    fr: "la taille",
  },
  CM: {
    en: "Cm",
    ar: "سم",
    tu: "Ağırlık",
    ru: "Вес",
    fr: "Poids",
  },
  SETTING: {
    en: "Setting",
    ar: "إعداد",
    tu: "Ayar",
    ru: "настройка",
    fr: "Réglage",
  },
  MEMBERS: {
    en: "Members",
    ar: "الأعضاء",
    tu: "Üyeler",
    ru: "Члены",
    fr: "Membres",
  },
  BRANCHES: {
    en: "Branches",
    ar: "فروع",
    tu: "Şubeler",
    ru: "Филиалы",
    fr: "Branches",
  },
  ADDRESS: {
    en: "Address",
    ar: "عنوان",
    tu: "Adres",
    ru: "Адрес",
    fr: "Adresse",
  },
  PHONE: {
    en: "Phone",
    ar: "هاتف",
    tu: "Telefon",
    ru: "Телефон",
    fr: "Téléphone",
  },
  SELECT: {
    en: "Select",
    ar: "تحديد",
    tu: "Seçmek",
    ru: "Выбрать",
    fr: "Sélectionner",
  },
  ADD_STUDENT: {
    en: "Add Student",
    ar: "اضافة طالب",
    tu: "Öğrenci",
    ru: "Студент",
    fr: "Étudiant",
  },
  ADD_NEW_STUDENT: {
    en: "Add New Student",
    ar: "اضافة طالب",
    tu: "Öğrenci",
    ru: "Студент",
    fr: "Étudiant",
  },
  EDIT_STUDENT: {
    en: "Edit Student",
    ar: "تعديل طالب",
    tu: "Öğrenciyi Düzenle",
    ru: "Изменить студента",
    fr: "Modifier l'élève",
  },
  DELETE_STUDENT: {
    en: "Delete Student",
    ar: "حذف طالب",
    tu: "Öğrenci",
    ru: "Студент",
    fr: "Étudiant",
  },
  A_NEW_STUDENT_HAS_BEEN_ADDED: {
    en: "A New Student Has Been Added Successfully",
    ar: "تم اضافة طالب جديد بنجاح",
    tu: "Öğrenci Başarıyla Silindi",
    ru: "Студент успешно удален",
    fr: "Étudiant supprimé avec succès",
  },
  STUDENT_HAS_BEEN_EDITED: {
    en: "The Student Has Been Edited Successfully",
    ar: "تم تعديل الطالب بنجاح",
    tu: "Öğrenci Başarıyla Silindi",
    ru: "Студент успешно удален",
    fr: "Étudiant supprimé avec succès",
  },
  DELETE_STUDENT_SUCCESS: {
    en: "The Student Has Been Deleted Successfully",
    ar: "تم حذف الطالب بنجاح",
    tu: "Öğrenci Başarıyla Silindi",
    ru: "Студент успешно удален",
    fr: "Étudiant supprimé avec succès",
  },
  TRANSFER_STUDENT_SUCCESS: {
    en: "Student Transfered Successfully",
    ar: "تم نقل الطالب بنجاح",
    tu: "Öğrenci Başarıyla Aktarıldı",
    ru: "Студент переведен успешно",
    fr: "Étudiant transféré avec succès",
  },
  DELETE_STUDENT_CONFIRMATION: {
    en: "Are You Sure You Want To Delete The Student?",
    ar: "هل أنت متأكد أنك تريد حذف الطالب؟",
    tu: "Silmek istediğine emin misin",
    ru: "Вы уверены, что хотите удалить",
    fr: "Etes-vous sûr que vous voulez supprimer",
  },
  TRANSFER_STUDENT_CONFIRMATION: {
    en: "Are You Sure You Want To Transfer This Student?",
    ar: "هل أنت متأكد أنك تريد نقل هذا الطالب؟",
    tu: "TransferTransfer Yapmak İstediğinizden Emin misiniz",
    ru: "ТрансферВы уверены, что хотите перевести",
    fr: "TransfertÊtes-vous sûr de vouloir transférer",
  },
  TRANSFER_STUDENT: {
    en: "Transfer Student",
    ar: "نقل طالب",
    tu: "Öğrenci",
    ru: "Студент",
    fr: "Étudiant",
  },
  TRANSFER: {
    en: "Transfer",
    ar: "نقل",
    tu: "Aktar",
    ru: "Передача",
    fr: "Transfert",
  },
  STUDENT: {
    en: "Student",
    ar: "طالب",
    tu: "Öğrenci",
    ru: "Студент",
    fr: "Étudiant",
  },
  NEW_MESSAGE: {
    en: "New Message",
    ar: "رسالة جديدة",
    tu: "Yeni Mesaj",
    ru: "Новое сообщение",
    fr: "Nouveau message",
  },
  ENTER_YOUR_MESSAGE: {
    en: "Enter Your Message",
    ar: "أدخل رسالتك",
    tu: "Mesajını gir",
    ru: "Введите свое сообщение",
    fr: "Entrez votre message",
  },
  SEARCH: {
    en: "Search",
    ar: "بحث",
    tu: "Arama",
    ru: "Поиск",
    fr: "Chercher",
  },
  PRINT: {
    en: "Print",
    ar: "طباعة",
    tu: "Yazdır",
    ru: "Распечатать",
    fr: "Imprimer",
  },
  COMPLETE_REQUEST: {
    en: "Complete Request",
    ar: "طلب كامل",
    tu: "Talebi tamamla",
    ru: "Завершить запрос",
    fr: "Demande complète",
  },
  EDIT_MEMBER: {
    en: "Edit Member",
    ar: "تعديل بيانات العضو ",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  EDIT_MEMBER_INFORMATION: {
    en: "Edit Member Information",
    ar: "تعديل بيانات العضو ",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  MEMBER_EDITED_SUCCESSFULLY: {
    en: "Member information has been edited successfully",
    ar: "تم تعديل بيانات العضو بنجاح",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  DELETE_MEMBER: {
    en: "Delete Member",
    ar: "حذف العضو",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  DELETE_MEMBER_CONFIRMATION: {
    en: "Are you sure you want to delete ",
    ar: "هل انت متأكد من حذف العضو؟",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  DELETE_MEMBER_SUCCESS: {
    en: "has been deleted successfully",
    ar: "تم حذف العضو بنجاح",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  CANCEL_REQUEST: {
    en: "Cancel Request",
    ar: "الطلب الغاء",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  CANCEL_REQUEST_CONFIRMATION: {
    en: "Are you sure you want to cancel the request?",
    ar: "هل انت متأكد من الغاء الطلب؟",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  CANCEL_REQUEST_SUCCESS: {
    en: "Request has been canceled successfully",
    ar: "تم الغاء الطلب بنجاح",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  WRONG_MEMBER: {
    en: "Wrong Member",
    ar: "عضو خاطئ",
    tu: "Yanlış Üye",
    ru: "Неправильный член",
    fr: "Mauvais membre",
  },
  DOCTOR: {
    en: "Doctor",
    ar: "طبيب",
    tu: "Doktor",
    ru: "Доктор",
    fr: "Docteur",
  },
  DOCTOR_NAME: {
    en: "Doctor's Name",
    ar: "اسم الطبيب",
    tu: "Doktor",
    ru: "Доктор",
    fr: "Docteur",
  },
  CHRONIC_DISEASES: {
    en: "Chronic Diseases",
    ar: "مرض مزمن",
    tu: "Kronik hastalık",
    ru: "Хроническое заболевание",
    fr: "Maladie chronique",
  },
  MEDICATION: {
    en: "Medication",
    ar: "دواء",
    tu: "İlaç tedavisi",
    ru: "Медикамент",
    fr: "Médicament",
  },
  IMMUNITYALLERGY: {
    en: "Immunity/Allergy",
    ar: "المناعة / الحساسية",
    tu: "Bağışıklık/Alerji",
    ru: "Иммунитет/аллергия",
    fr: "Immunité/Allergie",
  },
  IMMUNITY: {
    en: "Immunity",
    ar: "المناعة",
    tu: "Bağışıklık/Alerji",
    ru: "Иммунитет/аллергия",
    fr: "Immunité/Allergie",
  },
  ALLERGY: {
    en: "Allergy",
    ar: "الحساسية",
    tu: "Bağışıklık/Alerji",
    ru: "Иммунитет/аллергия",
    fr: "Immunité/Allergie",
  },
  DISEASE: {
    en: "Disease",
    ar: "المرض",
    tu: "Hastalıklar",
    ru: "Болезни",
    fr: "Maladies",
  },
  DISEASES: {
    en: "Diseases",
    ar: "الأمراض",
    tu: "Hastalıklar",
    ru: "Болезни",
    fr: "Maladies",
  },
  DISEASES_TYPE: {
    en: "Diseases Type",
    ar: "نوع الأمراض",
    tu: "Hastalıklar",
    ru: "Болезни",
    fr: "Maladies",
  },
  OTHER_DISEASES: {
    en: "Other Diseases",
    ar: "أمراض أخرى",
    tu: "Diğer hastalıklar",
    ru: "Другие болезни",
    fr: "Autres maladies",
  },
  INFECTIOUS_DISEASES: {
    en: "Infectious Diseases",
    ar: "أمراض معدية",
    tu: "Bulaşıcı hastalıklar",
    ru: "Инфекционные заболевания",
    fr: "Maladies infectieuses",
  },
  THIS_USER_ALREADY_EXIST: {
    en: "This User Already Exist",
    ar: "هذا المستخدم موجود بالفعل",
    tu: "Bu kullanıcı zaten var",
    ru: "Этот пользователь уже существует",
    fr: "Cet utilisateur existe déjà",
  },
  YOUR_RQUEST_HAS_BEEN_SENT: {
    en: "Your request has been sent",
    ar: "تم إرسال طلبك",
    tu: "Talebiniz gönderildi",
    ru: "Ваш запрос был отправлен",
    fr: "Votre demande a été envoyée",
  },
  // "NEW_CHECKUP_APPOINTMENT_HAS_BEEN_CREATED":{
  //   en: "New Checkup Appointment Has Been Created",
  //   ar: "تم إنشاء موعد فحص جديد",
  //   tu: "Yeni kontrol randevusu oluşturuldu",
  //   ru: "Была создана новая встреча",
  //   fr: "Un nouveau rendez-vous de contrôle a été créé",
  // },
  // "NEW_FOLLOW_UP_APPOINTMENT_HAS_BEEN_CREATED":{
  //   en: "New Follow Up Appointment Has Been Created",
  //   ar: "تم إنشاء موعد متابعة جديد",
  //   tu: "Yeni takip randevusu oluşturuldu",
  //   ru: "Была создана новая встреча",
  //   fr: "Un nouveau rendez-vous de suivi a été créé",
  // },
  NEW_APPOINTMENT_HAS_BEEN_CREATED: {
    en: "New Appointment Has Been Created",
    ar: "تم إنشاء موعد جديد",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  SAVE_APPOINTMENT_AND_SEND_REQUEST: {
    en: "Save Appointment & Send Request",
    ar: "حفظ الموعد وإرسال الطلب",
    tu: "Randevuyu kaydet ve isteği gönder",
    ru: "Сохранить встречу и отправить запрос",
    fr: "Enregistrer le rendez-vous et envoyer la demande",
  },
  EDIT_APPOINTMENT: {
    en: "Edit Appointment",
    ar: "تعديل الموعد",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  DELETE_APPOINTMENT: {
    en: "Delete Appointment",
    ar: "حذف الموعد",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  DELETE_REQUEST: {
    en: "Delete Request",
    ar: "حذف الطلب",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  DELETE_APPOINTMENT_CONFIRMATION_MESSAGE: {
    en: "Are You Sure You Want To Delete This Appointment?",
    ar: "هل أنت متأكد أنك تريد حذف هذا الموعد؟",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  CANCEL_APPOINTMENT: {
    en: "Cancel Appointment",
    ar: "الغاء الموعد",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  CANCEL_FREE_TRAIL: {
    en: "Cancel Free Trail",
    ar: "إلغاء النسخة التجريبية المجانية",
    tu: "Ücretsiz Denemeyi İptal Et",
    ru: "Отменить бесплатную пробную версию",
    fr: "Annuler l'essai gratuit",
  },
  CANCEL_APPOINTMENT_CONFIRMATION_MESSAGE: {
    en: "Are You Sure You Want To Cancel This Appointment?",
    ar: "هل أنت متأكد أنك تريد إلغاء هذا الموعد؟",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  UPDATE_APPOINTMENT: {
    en: "Update Appointment",
    ar: "تحديث الموعد",
    tu: "Randevuyu güncelle",
    ru: "Обновить встречу",
    fr: "Mettre à jour le rendez-vous",
  },
  APPOINTMENT_HAS_BEEN_UPDATED: {
    en: "Appointment Has Been Updated",
    ar: "تم تحديث الموعد",
    tu: "Randevu güncellendi",
    ru: "Встреча была обновлена",
    fr: "Le rendez-vous a été mis à jour",
  },
  APPOINTMENT_HAS_BEEN_CANCELED: {
    en: "The Appointment Has Been Canceled Successfully",
    ar: "تم الغاء الموعد بنجاح",
    tu: "Randevu güncellendi",
    ru: "Встреча была обновлена",
    fr: "Le rendez-vous a été mis à jour",
  },
  APPOINTMENT_HAS_BEEN_DELETED: {
    en: "The Appointment Has Been Deleted Successfully",
    ar: "تم حذف الموعد بنجاح",
    tu: "Randevu güncellendi",
    ru: "Встреча была обновлена",
    fr: "Le rendez-vous a été mis à jour",
  },
  WRONG_APPOINTMENT_ID: {
    en: "Wrong Appointment ID",
    ar: "معرف الموعد خاطئ",
    tu: "Yanlış randevu kimliği",
    ru: "Неверный идентификатор встречи",
    fr: "Mauvais identifiant de rendez-vous",
  },
  PRINT_REPORT: {
    en: "Print Report",
    ar: "طباعة التقرير",
    tu: "Yanlış randevu kimliği",
    ru: "Неверный идентификатор встречи",
    fr: "Mauvais identifiant de rendez-vous",
  },
  //*************FORGET-PASSWORD PAGE*************//
  VERIFICATION: {
    en: "Verification",
    ar: "التحقق",
    tu: "Doğrulama",
    ru: "Проверка",
    fr: "Vérification",
  },
  PHONE_NUMBER_VERIFICATION_CODE: {
    ar: "الرجاء إدخال رقم هاتفك لتلقي رمز التحقق",
    en: "Please Enter Your Phone Number To Recieve A Verification Code",
    tu: "Lütfen doğrulama kodunu almak için telefon numaranızı girin",
    ru: "Пожалуйста, введите свой номер телефона, чтобы получить код подтверждения",
    fr: "Veuillez saisir votre numéro de téléphone pour recevoir un code de vérification",
  },
  CHECK_MESSAGES_VERIFICATION_CODE: {
    en: "Please Check Your Messages For The Verification Code",
    ar: "الرجاء التحقق من رسائلك للحصول على رمز التحقق",
    tu: "Lütfen doğrulama kodunu almak için telefon numaranızı girin",
    ru: "Пожалуйста, введите свой номер телефона, чтобы получить код подтверждения",
    fr: "Veuillez saisir votre numéro de téléphone pour recevoir un code de vérification",
  },
  NEW_PASSWORD_HINT: {
    en: "Your New Password Must Be Different From Your Previous Password",
    ar: "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمة المرور السابقة",
    tu: "Yeni şifreniz önceki şifrenizden farklı olmalıdır",
    ru: "Ваш новый пароль должен отличаться от предыдущего пароля",
    fr: "Votre nouveau mot de passe doit être différent de votre mot de passe précédent",
  },

  //*************WELCOME PAGE*************//
  NOT_ADDED_BY_ORGANIZATION: {
    en: "You Are Not Added By Any Organizations please add organization or continue with ayamedica mobile application",
    ar: "لم يتم إضافتك من قبل أي منظمة ، يرجى إضافة منظمة أو المتابعة مع تطبيق Ayamedica",
    tu: "Herhangi bir kuruluş tarafından eklenmediniz, lütfen kuruluş ekleyin veya Ayamedica mobil uygulaması ile devam edin",
    ru: "Вы не добавлены никакими организациями, пожалуйста, добавьте организацию или продолжайте с мобильным приложением Ayamedica",
    fr: "Vous n'êtes ajouté par aucune organisation, veuillez ajouter une organisation ou continuer avec l'application mobile Ayamedica",
  },
  YOU_DO_NOT_HAVE_ORGANIZATION: {
    en: "You Don't Have Any Organization!!",
    ar: "ليس لديك أي منظمة !!",
    tu: "Yeni Kuruluş Ekle",
    ru: "Добавить новую организацию",
    fr: "Ajouter une nouvelle organisation",
  },
  ADD_NEW_ORGANIZATION: {
    en: "Add New Organization",
    ar: "إضافة منظمة جديدة",
    tu: "Yeni Kuruluş Ekle",
    ru: "Добавить новую организацию",
    fr: "Ajouter une nouvelle organisation",
  },
  CONTINUE_WITH_AYAMEDICA: {
    en: "Continue With Application",
    ar: "المتابعة مع التطبيق",
    tu: "Uygulamayla Devam Et",
    ru: "Продолжить с приложением",
    fr: "Continuer avec l'application",
  },

  //*************WORKLIST PAGE*************//
  WORKLIST: {
    en: "Worklist",
    ar: "قائمة العمل",
    tu: "İş Listesi",
    ru: "Рабочий список",
    fr: "Liste de travail",
  },
  ADD_NEW_APPOINTMENT: {
    en: "Add New Appointment",
    ar: "إضافة موعد جديد",
    tu: "Yeni Rand  Ekle",
    ru: "Добавить новое назначение",
    fr: "Ajouter un nouveau rendez-vous",
  },
  CHECK_IN: {
    ar: "الآن",
    en: "just now",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHECK_IN: {
    ar: "الآن",
    en: "just now",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  INDIVIDUALS: {
    en: "Individuals",
    ar: "الأفراد",
    tu: "Bireyler",
    ru: "Личности",
    fr: "Individus",
  },
  NO_OF_STUDENTS: {
    en: "Number Of Students",
    ar: "عدد الطلاب",
    tu: "Sınıflar",
    ru: "Классы",
    fr: "Classes",
  },
  NO_OF_MEDICAL_RECORDS: {
    en: "Number of Medical record",
    ar: "رقم السجلات الطبية",
    tu: "Tıbbi kayıt sayısı",
    ru: "Номер медицинской карты",
    fr: "Numéro de dossier médical",
  },
  GRADE_CLASS: {
    en: "Grade/Class",
    ar: "الصف/الفئة",
    tu: "Sınıf/Sınıf",
    re: "Kласс/Kласс",
    fr: "Grade/Classe",
  },
  CLASSES: {
    en: "Classes",
    ar: "الطبقات",
    tu: "Sınıflar",
    ru: "Классы",
    fr: "Classes",
  },
  ALL: {
    en: "All",
    ar: "الكل",
    tu: "Hepsi",
    ru: "Все",
    fr: "Tout",
  },
  CHECK_OUT: {
    en: "Check Out",
    ar: "تم الكشف",
    tu: "Randevu Kontrol Edildi",
    ru: "Проверенное назначение",
    fr: "Rendez-vous vérifié",
  },
  CANCELLED: {
    ar: "الآن",
    en: "just now",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHECKED_OUT: {
    en: "Checked Out",
    ar: "تم الكشف",
    tu: "Randevu Kontrol Edildi",
    ru: "Проверенное назначение",
    fr: "Rendez-vous vérifié",
  },
  CHECKED_OUT_SUCCESSFULLY: {
    en: "Checked Out Successfully",
    ar: "تم الكشف بنجاح",
    tu: "Randevu Kontrol Edildi",
    ru: "Проверенное назначение",
    fr: "Rendez-vous vérifié",
  },
  CHECKED_OUT_APPOINTMENT: {
    en: "Checked Out Appointment",
    ar: "الموعد المدقق",
    tu: "Randevu Kontrol Edildi",
    ru: "Проверенное назначение",
    fr: "Rendez-vous vérifié",
  },
  FILTER_APPOINTMENTS: {
    en: "Filter Appointments",
    ar: "تصفية المواعيد",
    tu: "Randevu Filtrele",
    ru: "Фильтр назначения",
    fr: "Filtrer le rendez-vous",
  },
  FILTER_APPOINTMENT: {
    en: "Filter Appointment",
    ar: "تصفية الموعد",
    tu: "Randevu Filtrele",
    ru: "Фильтр назначения",
    fr: "Filtrer le rendez-vous",
  },
  NUMBER_OF_CASES: {
    en: "Number Of Cases",
    ar: "عدد الحالات",
    tu: "Vaka Sayısı",
    ru: "Количество дел",
    fr: "Nombre de cas",
  },
  APPOINTMENT_TYPE: {
    en: "Appointment Type",
    ar: "نوع الموعد",
    tu: "Randevu Tipi",
    ru: "Тип встречи",
    fr: "Type de rendez-vous",
  },
  APPOINTMENT_CALENDAR: {
    en: "Appointment Calendar",
    ar: "تقويم المواعيد",
    tu: "Randevu Takvimi",
    ru: "Календарь встреч",
    fr: "Calendrier de rendez-vous",
  },
  VIEW_PROBLEM: {
    en: "View Problem",
    ar: "عرض المشكلة",
    tu: "Sorunu Görüntüle",
    ru: "Просмотр проблемы",
    fr: "Voir le problème",
  },
  VIEW_OUR_PLANS: {
    en: "View our plans to make correct decision",
    ar: "عرض خططنا لاتخاذ القرار الصحيح",
    tu: "Doğru karar vermek için planlarımızı görüntüleyin",
    ru: "Просмотрите наши планы, чтобы принять правильное решение",
    fr: "Consultez nos plans pour prendre la bonne décision",
  },
  VIEW_PLANS: {
    en: "View Plans",
    ar: "عرض خططنا",
    tu: "Doğru karar vermek için planlarımızı görüntüleyin",
    ru: "Просмотрите наши планы, чтобы принять правильное решение",
    fr: "Consultez nos plans pour prendre la bonne décision",
  },
  UNDO: {
    en: "Undo",
    ar: "فك",
    tu: "Geri Al",
    ru: "Расстегнуть",
    fr: "Défaire",
  },
  CHECK_UP: {
    en: "CheckUp",
    ar: "الفحص",
    tu: "Kontrol",
    ru: "Проверка",
    fr: "Vérifier",
  },
  FOLLOW_UP: {
    en: "Follow Up",
    ar: "متابعة",
    tu: "Takip",
    ru: "Следить",
    fr: "Suivre",
  },
  VACCINATION: {
    en: "Vaccination",
    ar: "التطعيم",
    tu: "Aşı",
    ru: "Вакцинация",
    fr: "Vaccination",
  },
  VACCINATION_TYPE: {
    en: "Vaccination Type",
    ar: "نوع التطعيم",
    tu: "Aşı",
    ru: "Вакцинация",
    fr: "Vaccination",
  },
  BLOODPRESSURE: {
    en: "Blood Pressure",
    ar: "ضغط الدم",
    tu: "Tansiyon",
    ru: "Артериальное давление",
    fr: "Pression artérielle",
  },
  HEARTDISEASE: {
    en: "Heart Disease",
    ar: "مرض قلبي",
    tu: "Kalp hastalığı",
    ru: "Сердечное заболевание",
    fr: "Cardiopathie",
  },
  LIVERDISEASE: {
    en: "Liver Disease",
    ar: "مرض الكبد",
    tu: "Karaciğer hastalığı",
    ru: "Болезнь печени",
    fr: "Maladie du foie",
  },
  BLOODGLUCOSE: {
    en: "Blood Glucose",
    ar: "جلوكوز الدم",
    tu: "Kan şekeri",
    ru: "Глюкоза крови",
    fr: "Glucose sanguin",
  },
  SUGERY: {
    en: "Sugery",
    ar: "جراحة",
    tu: "Ameliyat",
    ru: "Операция",
    fr: "Chirurgie",
  },
  WALK_IN: {
    en: "Walk In",
    ar: "حالة طوارئ",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  WALKIN_MODAL_CONTENT: {
    en: `The data has been saved in check in list. Would you like to cancel the appointment?`,
    ar: "",
    tu: "",
    ru: "",
    fr: "",
  },
  PHYSICAL_EXAM: {
    en: "Physical Exam",
    ar: "الإختبار الطبي",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NO_PHYSICAL_EXAM: {
    en: "No Physical Exam",
    ar: "لا يوجد  اختبار طبي",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  COMPLAINTS: {
    en: "Complaints",
    ar: "الشكاوى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  COMPLAINT: {
    en: "Complaint",
    ar: "الشكوى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NO_COMPLAINTS: {
    en: "No Complaints",
    ar: "لا يوجد شكاوى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  WRITE_HERE: {
    en: "Write Here",
    ar: "اكتب هنا",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  WHAT_IS_THE_STUDENT_COMPLAINONG_FROM: {
    en: "What is the student complaining from?",
    ar: "من ماذا يشتكى الطالب؟",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  COMPLAIN_ALREADY_EXISTS: {
    en: "Complain already exists",
    ar: "الشكوى موجودة بالفعل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ADD_COMPLAINT: {
    en: "Add a New Complaint",
    ar: "اضافة شكوى جديدة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EXAMINATION: {
    en: "Examination",
    ar: "الفحص",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TEMPERATURE: {
    en: "Temperature",
    ar: "درجة الحرارة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TECHNIQUE: {
    en: "Technique",
    ar: "درجة الحرارة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  HEART_RATE: {
    en: "Heart Rate",
    ar: "معدل ضربات القلب",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DESCRIPTION: {
    en: "Description",
    ar: "الوصف",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PULSE_TYPE: {
    en: "Pulse Type",
    ar: "نوع النبض",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  RESPIRATORY_RATE: {
    en: "Respiratory Rate",
    ar: "معدل التنفس",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  BLOOD_PRESSURE: {
    en: "Blood Pressure",
    ar: "ضغط الدم",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SITE: {
    en: "Site",
    ar: "المكان",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CUFF_SIZE: {
    en: "Cuff Size",
    ar: "حجم الذراع",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GROWTH_PARAMETERS: {
    en: "Growth Parameters",
    ar: "معلمات النمو",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GENERAL_FINDINGS: {
    en: "General Findings",
    ar: "النتائج العامة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GENERAL_COMMENTS: {
    en: "General Comments",
    ar: "ملاحظات العامة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  BIRTHMARKS: {
    en: "Birthmarks",
    ar: "الوحمات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  LESIONS: {
    en: "Lesions",
    ar: "الآفات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  OTHERS: {
    en: "Others",
    ar: "اخرى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ENLARGEMENT: {
    en: "Enlargement",
    ar: "enlargement",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  LOCATION: {
    en: "Location",
    ar: "Location",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MOVABLE: {
    en: "Movable",
    ar: "Movable",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CONSISTENCY: {
    en: "Consistency",
    ar: "اخرى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SIZE: {
    en: "Size",
    ar: "Size",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DEFAULT_SHAPE: {
    en: "Default Shape",
    ar: "Default Shape",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  UMBILICUS: {
    en: "Umbilicus",
    ar: "Umbilicus",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PALPATION: {
    en: "Palpation",
    ar: "Palpation",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  FRONTELLE_SHAPE: {
    en: "Frontelle Shape",
    ar: "Frontelle Shape",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SCALP_HAIR: {
    en: "Scalp And Hair",
    ar: "Scalp And Hair",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GENERAL: {
    en: "General",
    ar: "عام",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EOM: {
    en: "EOM",
    ar: "EOM",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PUPILS: {
    en: "Pupils",
    ar: "Pupils",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CONJUNCTIVA: {
    en: "Conjunctiva",
    ar: "Conjunctiva",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SCLERA: {
    en: "Sclera",
    ar: "Sclera",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CORNEA: {
    en: "Cornea",
    ar: "Cornea",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PLUGGING_OF_DUCT: {
    en: "Plugging Of Nasolacrimal Duct",
    ar: "Plugging Of Nasolacrimal Duct",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  RED_REFLEX: {
    en: "Red Reflex",
    ar: "Red Reflex",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  VISUAL_FIELDS: {
    en: "Visual Fields",
    ar: "Visual Fields",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TYMPANIC_MEMBRANE: {
    en: "Tympanic Membrane",
    ar: "Tympanic Membrane",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  POSITION_OF_EARS: {
    en: "Position Of Ears Gross",
    ar: "Position Of Ears Gross",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  HEARING_ASSESSMENT: {
    en: "Hearing General Assessment",
    ar: "Hearing General Assessment",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NASAL_SEPTUM: {
    en: "Nasal Septum",
    ar: "Nasal Septum",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MUCOSA: {
    en: "Mucosa",
    ar: "Mucosa",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SINUS_TENDERNESS: {
    en: "Sinus Tenderness",
    ar: "التهاب الجيوب الأنفية",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DISCHARGE: {
    en: "Discharge",
    ar: "افرازات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  LIP_COLOR: {
    en: "Lip Color",
    ar: "لون الشفاه",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  OTHER_NOTES: {
    en: "Other Notes",
    ar: "ملاحظات اخرى",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  BUCCAL_MUCOSA_COLOR: {
    en: "Buccal Mucosa Color",
    ar: "لون الغشاء المخاطي الشدق",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TEETH_NUMBER: {
    en: "Teeth Number",
    ar: "عدد الأسنان",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CONDITION: {
    en: "Condition",
    ar: "الحالة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TONGUE: {
    en: "Tongue",
    ar: "اللسان",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PALATE: {
    en: "Palate",
    ar: "السن",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TONSILS: {
    en: "Tonsils",
    ar: "اللثة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EXUDATE: {
    en: "Exudate",
    ar: "إفراز",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GAG_REFLEX: {
    en: "Gag Reflex",
    ar: "Gag Reflex",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  POSTERIOR_PHARYNEAL_WALL: {
    en: "Posterior Pharyneal Wall",
    ar: "الجدار البلعومي الخلفي",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  THYROID: {
    en: "Thyroid",
    ar: "غدة درقية",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TRACHEAL_POSITION: {
    en: "Tracheal Position",
    ar: "مكان القصبة الهوائية",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MASSES: {
    en: "Masses",
    ar: "الكتل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NUCHAL_RIGIDITY: {
    en: "Nuchal Rigidity",
    ar: "Nuchal Rigidity",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  BREATHING_PATTERN: {
    en: "Breathing Pattern",
    ar: "نمط التنفس",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CHEST_WALL_CONFIG: {
    en: "Chest Wall Configuration",
    ar: "تكوين جدار الصدر",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  AUSCULTATION: {
    en: "Auscultation",
    ar: "التسمع",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ACCESSORY_MMS_USAGE: {
    en: "Accessory Mms Usage",
    ar: "Accessory Mms Usage",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  RHYTHM: {
    en: "Rhythm",
    ar: "Rhythm",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MURMUR: {
    en: "Murmur",
    ar: "Murmur",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  HEART_SOUND_QUALITY: {
    en: "Heart Sound Quality",
    ar: "Heart Sound Quality",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PULSE_QUALITY: {
    en: "Pulse Quality",
    ar: "Pulse Quality",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  BACK_BODY: {
    en: "Back",
    ar: "الظهر",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  JOINT: {
    en: "Joint",
    ar: "المفصل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MUSCLES: {
    en: "Muscles",
    ar: "العضلات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EXTREMITIES: {
    en: "Extremities",
    ar: "Extremities",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  GAIT: {
    en: "Gait",
    ar: "Gait",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CRANIAL_NERVES: {
    en: "Cranial Nerves",
    ar: "الأعصاب الدماغية",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SENSATION: {
    en: "Sensation",
    ar: "الإحساس",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CEREBELLUM: {
    en: "Cerebellum",
    ar: "المخيخ",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MUSCLE_TONE_STRENGTH: {
    en: "Muscle Tone & Strength",
    ar: "قوة العضلات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DTR_REFLEX: {
    en: "DTR Reflex",
    ar: "DTR Reflex",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SUPERFICIAL_REFLEX: {
    en: "Superficial Reflex",
    ar: "Superficial Reflex",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ABDOMINAL: {
    en: "Abdominal",
    ar: "البطني",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NEONATAL_PRIMITIVE_REFLEX: {
    en: "Neonatal Primitive Reflex",
    ar: "Neonatal Primitive Reflex",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EXTERNAL_GENITALIA: {
    en: "External Genitalia",
    ar: "External Genitalia",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CRYPTOCHIDISIM: {
    en: "Cryptochidisim",
    ar: "Cryptochidisim",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  INITIAL_LAB_RESULTS: {
    en: "Initial Lab Results",
    ar: "Initial Lab Results",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DIAGNOSTIC_REPORTS: {
    en: "Diagnostic Reports",
    ar: "Diagnostic Reports",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ASSESSMENT: {
    en: "Assessment",
    ar: "التشخيص",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NO_ASSESSMENT: {
    en: "No Assessment",
    ar: "لا يوجد تشخيص",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CONCLUSION: {
    en: "Conclusion",
    ar: "النتيجة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  RECOGNITION: {
    en: "Recognition",
    ar: "التعرف",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PROBLEM: {
    en: "Problem",
    ar: "المشكلة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PROBLEM_DETAILS: {
    en: "Problem Details",
    ar: "تفاصيل المشكلة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DIAGNOSIS: {
    en: "Diagnosis",
    ar: "التشخيص",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NOTE: {
    en: "Note",
    ar: "ملاحظة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PLAN_NOTES: {
    en: "Plan Notes",
    ar: "ملاحظات الخطة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NOTES: {
    en: "Notes",
    ar: "ملاحظات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ADD_NEW_NOTE: {
    en: "Add A New Note",
    ar: "اضف ملاحظة جديدة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PLAN: {
    en: "Plan",
    ar: "العلاج",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NO_MEDICATION: {
    en: "No Medication",
    ar: "لا يوجد دواء",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CURRENT_MEDICATION: {
    en: "Current Medication",
    ar: "الدواء الحالي",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NO_MEDICATION_FOUND_IN_PLAN: {
    en: "Currently, The patient is not under any other medications",
    ar: "لا يوجد دواء حاليا",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  TREATMENT: {
    en: "Treatment",
    ar: "العلاج",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SICK_LEAVE: {
    en: "Sick Leave",
    ar: "أجازة مرضية",
    tu: "Hastalık izni",
    ru: "Больничный лист",
    fr: "Congé de maladie",
  },
  MEDICATION_DURING_VISIT: {
    en: "Medication During This Visit",
    ar: "الدواء خلال هذه الزيارة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ACTIVE_INGRADIENT: {
    en: "Active Ingredient",
    ar: "المكون النشط",
    tu: "Aktif bileşen",
    ru: "Активное вещество",
    fr: "Ingrédient actif",
  },
  DRUG_NAME: {
    en: "Drug Name",
    ar: "اسم الدواء",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ADMINISTRATION_FORM: {
    en: "Administration Form",
    ar: "نوع الادوية",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  RELATION_TO_FOOD: {
    en: "Relation to Food",
    ar: "العلاقة بالطعام",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  START_DATE: {
    en: "Start Date",
    ar: "تاريخ البدء",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  END_DATE: {
    en: "End Date",
    ar: "تاريخ الانتهاء",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PILLS: {
    en: "Pills",
    ar: "حبات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  SHOTS: {
    en: "Shots",
    ar: "حقن",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  EVERY: {
    en: "Every",
    ar: "كل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  HOURS: {
    en: "Hours",
    ar: "ساعات",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  FOR: {
    en: "For",
    ar: "لمدة",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DAYS: {
    en: "Days",
    ar: "أيام",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  NUMBER: {
    en: "No.",
    ar: "عدد",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  ADD_DRUG: {
    en: "Add Drug",
    ar: "اضف الدواء",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DRUG_ALREADY_EXISTS: {
    en: "Drug already exists",
    ar: "الدواء موجود بالفعل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  PROBLEM_ALREADY_EXISTS: {
    en: "Problem already exists",
    ar: "المشكلة موجود بالفعل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  DIAGNOSIS_ALREADY_EXISTS: {
    en: "Diagnosis already exists",
    ar: "التشخيص موجود بالفعل",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  CREATE_A_CHECK_UP_APPOINTMENT: {
    en: "Create a Check Up Appointment",
    ar: "إنشاء موعد التحقق",
    tu: "Bir Kontrol Randevusu Oluştur",
    ru: "Создать назначение на проверку",
    fr: "Créer un rendez-vous de contrôle",
  },
  CREATE_A_FOLLOW_UP_APPOINTMENT: {
    en: "Create a Follow Up Appointment",
    ar: "إنشاء موعد متابعة",
    tu: "Bir Takip Randevusu Oluştur",
    ru: "Создать назначение на последующее назначение",
    fr: "Créer un rendez-vous de suivi",
  },
  CREATE_A_VACCINATION_APPOINTMENT: {
    en: "Create a Vaccination Appointment",
    ar: "إنشاء موعد التطعيم",
    tu: "Bir Aşı Randevusu Oluştur",
    ru: "Создать назначение на вакцинацию",
    fr: "Créer un rendez-vous de vaccination",
  },
  CREATE_A_WALK_IN_APPOINTMENT: {
    en: "Create a Walk In Appointment",
    ar: "إنشاء موعد حالة طوارئ",
    tu: "Bir Yürüme Randevusu Oluştur",
    ru: "Создать назначение на прогулку",
    fr: "Créer un rendez-vous de marche",
  },
  ALL_STUDENTS: {
    en: "All Students",
    ar: "جميع الطلاب",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  SELECTED_STUDENTS: {
    en: "Selected Students",
    ar: "جميع الطلاب",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  ENTER_STUDENT_NAME: {
    en: "Enter Student Name",
    ar: "جميع الطلاب",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  SELECTED_DISEASES: {
    en: "Selected Diseases",
    ar: "أمراض محددة",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  SELECT_TIME: {
    en: "Select Time",
    ar: "حدد الوقت",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  SAVE_APPOINTMENT: {
    en: "Save Appointment",
    ar: "حفظ الموعد",
    tu: "Randevuyu Kaydet",
    ru: "Сохранить назначение",
    fr: "Enregistrer le rendez-vous",
  },
  SEARCH_BY_AID: {
    en: "Search by A-ID",
    ar: "البحث عن طريق A-ID",
    tu: "A-ID ile Ara",
    ru: "Поиск по A-ID",
    fr: "Rechercher par A-ID",
  },
  SEARCH_BY_GRADE: {
    en: "Search by Grade",
    ar: "البحث حسب الصف",
    tu: "Sınıf ile Ara",
    ru: "Поиск по классу",
    fr: "Rechercher par grade",
  },
  SEARCH_BY_NAME_OR_AID: {
    en: "Search by A-ID",
    ar: "البحث حسب الاسم أو A-ID",
    tu: "A-ID veya Ad ile Ara",
    ru: "Поиск по имени или A-ID",
    fr: "Rechercher par nom ou A-ID",
  },
  START_APPOINTMENT: {
    en: "Start Appointment",
    ar: "بدء الموعد",
    tu: "Randevuyu Başlat",
    ru: "Начать назначение",
    fr: "Commencer le rendez-vous",
  },
  CHECK_IN: {
    en: "Check In",
    ar: "تسجيل الدخول",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },

  THERE_ARE_NO_CHEKEDOUT_APPOINTMENTS: {
    en: "There are no checked out appointments",
    ar: "لا توجد مواعيد مسحوبة",
    tu: "Hiçbir çıkışlı randevu yok",
    ru: "Нет проверенных назначений",
    fr: "Il n'y a pas de rendez-vous vérifiés",
  },
  APPOINTMENTS: {
    en: "Appointments",
    ar: "مواعيد",
    tu: "Hiçbir randevu yok",
    ru: "Нет назначений",
    fr: "Il n'y a pas de rendez-vous",
  },
  APPOINTMENT_DETAILS: {
    en: "Appointment Details",
    ar: "تفاصيل المعاد",
    tu: "Hiçbir randevu yok",
    ru: "Нет назначений",
    fr: "Il n'y a pas de rendez-vous",
  },
  THERE_ARE_NO_APPOINTMENTS: {
    en: "There are no appointments",
    ar: "لا توجد مواعيد",
    tu: "Hiçbir randevu yok",
    ru: "Нет назначений",
    fr: "Il n'y a pas de rendez-vous",
  },

  //*************INBOX PAGE*************//
  INBOX: {
    en: "Inbox",
    ar: "صندوق الوارد",
    tu: "Gelen Kutusu",
    ru: "Входящие",
    fr: "Boîte de réception",
  },
  SUBJECT: {
    en: "Subject",
    ar: "موضوع",
    tu: "Konu",
    ru: "Тема",
    fr: "Matière",
  },
  TEXT_BODY: {
    en: "Body Text",
    ar: "النص",
    tu: "Metin",
    ru: "Текст",
    fr: "Texte",
  },
  GO_TO_INBOX: {
    en: "Go To Inbox",
    ar: "الذهاب لصندوق الوارد",
    tu: "Gelen Kutusu",
    ru: "Входящие",
    fr: "Boîte de réception",
  },
  SEND_MESSAGE: {
    en: "Send Message",
    ar: "إرسال رسالة",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  SHOW_MORE: {
    en: "Show More",
    ar: "اعرض المزيد",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  VIEW_MORE: {
    en: "View More",
    ar: "اعرض المزيد",
    tu: "Yürü",
    ru: "Пройти",
    fr: "Marcher",
  },
  MESSAGE_SENT_SUCCESSFULLY: {
    en: "Message Sent Successfully",
    ar: "تم إرسال الرسالة بنجاح",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  FILTER_MESSAGES: {
    en: "Filter Message",
    ar: "تصفية الرسائل",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  NO_MESSAGES: {
    en: "No Messages",
    ar: "لا توجد رسائل",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  NO_RECORDS: {
    en: "No Records",
    ar: "لا توجد سجلات",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  NO_REQUESTS: {
    en: "No Requests",
    ar: "لا توجد طلبات",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  REQUESTS: {
    en: "Requests",
    ar: "طلبات",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  MESSAGES: {
    en: "Messages",
    ar: "رسائل",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  RECORDS: {
    en: "Records",
    ar: "السجلات",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },
  CREATE_MESSAGE: {
    en: "Create Message",
    ar: "إنشاء رسالة",
    tu: "Mesaj Oluştur",
    ru: "Создать сообщение",
    fr: "Créer un message",
  },
  SENT_MESSAGES: {
    en: "Sent Messages",
    ar: "ارسال رسالة",
    tu: "Gönderilen Mesajlar",
    ru: "Отправленные сообщения",
    fr: "Messages envoyés",
  },
  RECEIVED_MESSAGES: {
    en: "Received Messages (12)",
    ar: "الرسائل المستلمة",
    tu: "Alınan Mesajlar",
    ru: "Полученные сообщения",
    fr: "Messages reçus",
  },
  VACCINE_MESSAGES: {
    en: "Vaccine Messages (100)",
    ar: "رسائل التطعيم",
    tu: "Aşı Mesajları",
    ru: "Сообщения о вакцинации",
    fr: "Messages de vaccination",
  },
  DELETE_MESSAGE: {
    en: "Delete Message",
    ar: "حذف الرسالة",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  DELETE_MESSAGE_CONFIRMATION_MESSAGE: {
    en: "Are You Sure You Want To Delete This Message?",
    ar: "هل أنت متأكد أنك تريد حذف هذه الرسالة؟",
    tu: "Randevuyu düzenle",
    ru: "Изменить встречу",
    fr: "Modifier le rendez-vous",
  },
  MESSAGE_HAS_BEEN_DELETED: {
    en: "The Message Has Been Deleted Successfully",
    ar: "تم حذف الرسالة بنجاح",
    tu: "Randevu güncellendi",
    ru: "Встреча была обновлена",
    fr: "Le rendez-vous a été mis à jour",
  },
  UPLOAD_FILE: {
    en: "Upload File",
    ar: "تحميل الملف",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  BROWSE: {
    en: "Browse",
    ar: "تصفح",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  OR: {
    en: "Or",
    ar: "او",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  DRAG_AND_DROP: {
    en: "Drag And Drop Files To Upload",
    ar: "اسحب وأسقط الملف للتحميل",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  UPLOADING_EXCEL_FILE: {
    en: "Uploading Excel File",
    ar: "تحميل الملف الاكسل",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  UPLOAD_ATTACHMENT: {
    en: "Upload Attachment",
    ar: "تحميل المرفق",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  ATTACHMENT: {
    en: "Attachment",
    ar: "المرفق",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  UPLOAD_STUDENTS: {
    en: "Upload Students",
    ar: "تحميل الطلاب",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  REVIEW_AND_SUBMIT: {
    en: "Review & Submit",
    ar: "مراجعة و تسجبل",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  SUBMITTING: {
    en: "Submitting",
    ar: "جارى التسجبل",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  SAVE_EXCEL_CONFIRMATION_MESSAGE: {
    en: "Are You Sure You Want To Save The Data?",
    ar: "هل أنت متأكد من حفظ البيانات؟",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  EXPORT_FILE: {
    en: "Export File",
    ar: "تصدير الملف",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  EXPORT_ERROR_MESSAGE: {
    en: "You Have to Review And Submit The Correct Students First Before Exporting The Error File",
    ar: "يجب عليك مراجعة و تسجيل الطلاب الصحيحين أولاً قبل تصدير الملف الخطأ",
    tu: "Ek Dosya Yükle",
    ru: "Загрузить вложение",
    fr: "Télécharger la pièce jointe",
  },
  MESSAGE: {
    en: "Message",
    ar: "رسالة",
    tu: "Mesaj",
    ru: "Сообщение",
    fr: "Message",
  },
  ACCEPTED: {
    en: "Accepted",
    ar: "مقبول",
    tu: "Kabul Edildi",
    ru: "Принято",
    fr: "Accepté",
  },
  PENDING: {
    en: "Pending",
    ar: "قيد الانتظار",
    tu: "Beklemede",
    ru: "В ожидании",
    fr: "En attente",
  },
  DENIED: {
    en: "Denied",
    ar: "مرفوض",
    tu: "Reddedildi",
    ru: "Отклонено",
    fr: "Refusé",
  },
  DECLINED: {
    en: "Declined",
    ar: "مرفوض",
    tu: "Reddedildi",
    ru: "Отклонено",
    fr: "Refusé",
  },
  VACCINE_REQUEST: {
    en: "Vaccine Request",
    ar: "طلب تطعيم",
    tu: "Aşı Talebi",
    ru: "Запрос на вакцинацию",
    fr: "Demande de vaccination",
  },
  VACCINE: {
    en: "Vaccine ",
    ar: "تطعيم",
    tu: "Aşı Talebi",
    ru: "Запрос на вакцинацию",
    fr: "Demande de vaccination",
  },
  NAME_OF_SUBJECT: {
    en: "Name of Subject",
    ar: "اسم الموضوع",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  SELECT_CLASS: {
    en: "Select Class",
    ar: "اختر الفصل",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  SELECT_GRADE: {
    en: "Select Grade",
    ar: "اختر الصف",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  SELECT_STUDENTS: {
    en: "Select Student",
    ar: "اختر الطلبه",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  SELECT_DOCTOR: {
    en: "Select Doctor",
    ar: " اختر الطبيب",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  RESPONSES: {
    en: "Responses",
    ar: "الردود",
    tu: "Konunun Adı",
    ru: "Название темы",
    fr: "Nom de l'objet",
  },
  //*************STUDENT PAGE*************//
  STUDENTS: {
    en: "Students",
    ar: "الطلاب",
    tu: "Öğrenciler",
    ru: "Студенты",
    fr: "Étudiants",
  },
  FILTER_STUDENTS: {
    en: "Filter Students",
    ar: "تصفية الطلاب",
    tu: "Фильтровать студентов",
    ru: "Öğrencileri Filtrele",
    fr: "Filtrer les étudiants",
  },
  FILTER_MESSAGE: {
    en: "Filter Messages",
    ar: "تصفيه الرسائل ",
    tu: "Mesaj Gönder",
    ru: "Отправить сообщение",
    fr: "Envoyer un message",
  },

  //*************USER_PICTURE_OPTIONS*************//
  SWITCH_ACCOUNT: {
    en: "Switch Branch",
    ar: "تبديل الفرع",
    tu: "Hesap Değiştir",
    ru: "Сменить аккаунт",
    fr: "Changer de compte",
  },
  ORGANIZATION_SETTING: {
    en: "Organization Setting",
    ar: "إعدادات المنظمة",
    tu: "Kuruluş Ayarları",
    ru: "Настройка организации",
    fr: "Paramètres de l'organisation",
  },
  NATIONALITY: {
    en: "Nationality",
    ar: "الجنسية",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  COUNTRY_OF_CITIZENSHIP: {
    en: "Country of Citizenship",
    ar: "بلد الجنسية",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  POLICY_NUMBER: {
    en: "Policy Number",
    ar: "رقم وثيقة التأمين",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  PROFILE: {
    en: "Profile",
    ar: "الملف الشخصي",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  MY_PROFILE: {
    en: "My Profile",
    ar: "ملفي الشخصي",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  YOUR_PROFILE_HAS_BEEN_UPDATED: {
    en: "Your profile has been Updated",
    ar: "تم تحديث ملفك الشخصي",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },

  //*************CALENDAR_PAGE*************//
  DATE_FORMAT: {
    en: "en-US",
    ar: "ar-SA",
    tu: "tr-TR",
    ru: "ru-RU",
    fr: "fr-FR",
  },

  //*************USER_PROFILE_PAGE*************//
  GENERAL_INFORMATION: {
    en: "General Information",
    ar: "معلومات عامة",
    tu: "Genel Bilgi",
    ru: "Общая информация",
    fr: "Informations générales",
  },
  RDS: {
    en: "RDS",
    ar: "س ط",
    tu: "Tıbbi kayıtlar",
    ru: "Медицинские записи",
    fr: "Dossiers médicaux",
  },
  MEDICAL_RECORDS: {
    en: "Medical Records",
    ar: "سجلات طبية",
    tu: "Tıbbi kayıtlar",
    ru: "Медицинские записи",
    fr: "Dossiers médicaux",
  },
  MEDICAL_RECORDS_NUMBER: {
    en: "No. Medical Records",
    ar: "عدد السجلات الطبية",
    tu: "Tıbbi kayıtlar",
    ru: "Медицинские записи",
    fr: "Dossiers médicaux",
  },
  NO_MEDICAL_RECORDS: {
    en: "There Are No Medical Records Yet",
    ar: "لا توجد سجلات طبية حتى الآن",
    tu: "Tıbbi kayıtlar",
    ru: "Медицинские записи",
    fr: "Dossiers médicaux",
  },
  NO_INFORMATION_ABOUT_MEDICAL_RECORD: {
    en: "No information about the medical process was registered by the doctor.",
    ar: "",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_INFORMATION_ABOUT_THIS_SECTION: {
    en: "No information about this section has been registered by the doctor",
    ar: "",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_CURRENT_MEDICATION_CREATED: {
    en: "No current medication found when creating this appointment",
    ar: "",
    tu: "",
    ru: "",
    fr: "",
  },
  MEDICAL_HISTORY: {
    en: "Medical History",
    ar: "تاريخ طبى",
    tu: "Tıbbi geçmiş",
    ru: "История болезни",
    fr: "Antécédents médicaux",
  },
  EDIT_YOUR_INFORMATION: {
    en: "Edit Your Information",
    ar: "تعديل معلوماتك",
    tu: "Bilgilerini Düzenle",
    ru: "Изменить свою информацию",
    fr: "Modifier vos informations",
  },
  NO_PERMISSION_MESSAGE: {
    en: "You Don't Have Permission To Access The Worklist",
    ar: "ليس لديك إذن للوصول إلى قائمة الأعمال",
    tu: "İzin",
    ru: "Разрешение",
    fr: "Autorisation",
  },
  PERMISSION: {
    en: "Permission",
    ar: "إذن",
    tu: "İzin",
    ru: "Разрешение",
    fr: "Autorisation",
  },
  POSITION: {
    en: "Position",
    ar: "موضع",
    tu: "Pozisyon",
    ru: "Позиция",
    fr: "Position",
  },

  //*************EDIT_INFORMATION_COMPONENT*************//
  INSURANCE_COMPANY: {
    en: "Insurance Company",
    ar: "شركة التأمين",
    tu: "Sigorta Şirketi",
    ru: "Страховая компания",
    fr: "Compagnie d'assurance",
  },
  MEMBER_ID: {
    en: "Member ID",
    ar: "هوية العضو",
    tu: "Üye Kimliği",
    ru: "Идентификатор участника",
    fr: "ID membre",
  },
  GROUP_NUMBER: {
    en: "Group Number",
    ar: "رقم المجموعة",
    tu: "Grup Numarası",
    ru: "Номер группы",
    fr: "Numéro de groupe",
  },
  GROUP: {
    en: "Group",
    ar: "مجموعة",
    tu: "Grup Numarası",
    ru: "Номер группы",
    fr: "Numéro de groupe",
  },
  PHOTO_URL: {
    en: "Photo URL",
    ar: "رابط الصورة",
    tu: "Grup Numarası",
    ru: "Номер группы",
    fr: "Numéro de groupe",
  },

  //*************ORGANIZATION_SETTING_PAGE*************//
  NO_DATA: {
    en: "There is no data to display",
    ar: "لا يوجد بيانات لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  PENDING_REQUEST: {
    en: "The request is still pending",
    ar: "الطلب مازال قيد الانتظار",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  TOTAL_STUDENTS: {
    en: "Total Students",
    ar: "اجمالى الطلاب",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  NO_STUDENTS: {
    en: "There are no students to display",
    ar: "لا يوجد طلاب لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  NO_MEMBERS: {
    en: "There are no members to display",
    ar: "لا يوجد اعضاء لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  NO_BRANCHES: {
    en: "There are no branches to display",
    ar: "لا يوجد فروع لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  ADD_A_NEW_BRANCH: {
    en: "Add a new branch",
    ar: "إضافة فرع جديد",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  ADD_A_NEW_MEMBER: {
    en: "Add a new member",
    ar: "إضافة عضو جديد",
    tu: "yeni üye ekle",
    ru: "Добавить нового участника",
    fr: "Ajouter un nouveau membre",
  },
  STATE: {
    en: "State",
    ar: "حالة",
    tu: "Durum",
    ru: "Состояние",
    fr: "Etat",
  },
  PENDING_MEMBERS: {
    en: "Pending Members",
    ar: "الأعضاء المعلقين",
    tu: "Bekleyen Üyeler",
    ru: "Ожидающие участники",
    fr: "Membres en attente",
  },
  DOWNLOAD_TEMPLATE: {
    en: "Download Template",
    ar: "تحميل القالب",
    tu: "Şablonu İndir",
    ru: "Скачать шаблон",
    fr: "Télécharger le modèle",
  },
  SEND_REQUEST: {
    en: "Send Request",
    ar: "إرسال طلب",
    tu: "İstek Gönder",
    ru: "Отправить запрос",
    fr: "Envoyer la demande",
  },
  ORGANIZATION_NAME: {
    en: "Organization Name",
    ar: "اسم المنظمة",
    tu: "Organizasyon Adı",
    ru: "Название организации",
    fr: "Nom de l'organisation",
  },
  BRANCH_NAME: {
    en: "Branch Name",
    ar: "اسم الفرع",
    tu: "Şube Adı",
    ru: "Название филиала",
    fr: "Nom de la branche",
  },
  NEW_BRANCH_HAS_BEEN_CREATED: {
    en: "New Branch Has Been Created",
    ar: "تم إنشاء فرع جديد",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  EDIT_BRANCH: {
    en: "Edit Branch",
    ar: "تعديل الفرع",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  EDIT_INFORMATION: {
    en: "Edit Information",
    ar: "تعديل البيانات",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  BRANCH_HAS_BEEN_EDITED: {
    en: "The Branch Has Been Edited Successfully",
    ar: "تم تعديل الفرع بنجاح",
    tu: "Yeni randevu oluşturuldu",
    ru: "Была создана новая встреча",
    fr: "Un nouveau rendez-vous a été créé",
  },
  DELETE_BRANCH_CONFIRMATION: {
    en: "Are You Sure You Want To Delete The Branch?",
    ar: "هل انت متأكد من حذف الفرع؟",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  DELETE_BRANCH_SUCCESS: {
    en: "Branch Deleted Successfully",
    ar: "تم حذف الفرع بنجاح",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  DELETE_BRANCH: {
    en: "Delete Branch",
    ar: "حذف الفرع",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  BRANCH_INFO: {
    en: "Branch Info",
    ar: "معلومات الفرع",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  EDIT_BRANCH_INFORMATION: {
    en: "Edit Branch Information",
    ar: "تعديل معلومات الفرع",
    tu: "Şube Bilgilerini Düzenle",
    ru: "Изменить информацию о филиале",
    fr: "Modifier les informations de la branche",
  },
  UPLOAD_SCHOOL_DATABASE: {
    en: "Upload School Database",
    ar: "تحميل قاعدة بيانات المدرسة",
    tu: "Okul Veritabanını Yükle",
    ru: "Загрузить базу данных школы",
    fr: "Télécharger la base de données de l'école",
  },
  ADD_BRANCH_ERROR: {
    en: "Branch Name, Email and phone Number must be unique",
    ar: "يجب أن يكون اسم الفرع والبريد الإلكتروني ورقم الهاتف فريدين",
    tu: "Şube Adı, E-posta ve Telefon Numarası benzersiz olmalıdır",
    ru: "Название филиала, электронная почта и номер телефона должны быть уникальными",
    fr: "Le nom de la branche, l'e-mail et le numéro de téléphone doivent être uniques",
  },
  NO_GRADES: {
    en: "There are no grades to display",
    ar: "لا يوجد مراحل لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  NO_CLASSES: {
    en: "There are no classes to display",
    ar: "لا يوجد فصول لعرضها",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  NO_GRDAES_IN_BRANCH: {
    en: "There are no grades. if you want to add grades, go to edit branch info to add.",
    ar: "لا يوجد مراحل. إذا كنت تريد إضافة مراحل ، انتقل إلى تعديل معلومات الفرع لإضافتها.",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  FILTER_CLASSES: {
    en: "Filter Classes",
    ar: "تصفية الفصول",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  FILTER_RECORDS: {
    en: "Filter Records",
    ar: "سجلات التصفية",
    tu: "Kayıtları Filtrele",
    ru: "Фильтровать записи",
    fr: "Filtrer les enregistrements",
  },
  FILTER_VACCINES: {
    en: "Filter Vaccine",
    ar: "سجلات التصفية",
    tu: "Kayıtları Filtrele",
    ru: "Фильтровать записи",
    fr: "Filtrer les enregistrements",
  },
  FILTER: {
    en: "Filter",
    ar: "تصفية",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  FILTER_BY_DATE: {
    en: "Filter By Date",
    ar: "تصفية حسب التاريخ",
    tu: "Tarihe Göre Filtrele",
    ru: "Фильтровать по дате",
    fr: "Filtrer par date",
  },
  RESET: {
    en: "Reset",
    ar: "إعادة",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  ADD_CLASS: {
    en: "Add Class",
    ar: "إضافة فصل",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  EDIT_CLASS: {
    en: "Edit Class",
    ar: "تعديل الفصل",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  DELETE_CLASS: {
    en: "Delete Class",
    ar: "حذف الفصل",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  ADD_CLASSES: {
    en: "Add Classes",
    ar: "إضافة فصول",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  CONTINUE_LATER: {
    en: "Continue Later",
    ar: "متابعة لاحقا",
    tu: "yeni şube ekle",
    ru: "Добавить новую ветку",
    fr: "Ajouter une nouvelle branche",
  },
  //*************GENERAL_INFORMATION_COMPONENT*************//
  BLOOD_TYPE: {
    en: "Blood Type",
    ar: "فصيلة الدم",
    tu: "Kan Grubu",
    ru: "Группа крови",
    fr: "Groupe sanguin",
  },
  HOME_ADDRESS: {
    en: "Home Address",
    ar: "عنوان المنزل",
    tu: "Ev Adresi",
    ru: "Домашний адрес",
    fr: "Adresse du domicile",
  },
  FIRST_GUARDIAN: {
    en: "First Guardian",
    ar: "الوصي الأول",
    tu: "İlk Vasi",
    ru: "Первый опекун",
    fr: "Premier gardien",
  },
  F_GUARDIAN: {
    en: "F-Guardian",
    ar: "رقم الوصي",
    tu: "Vasinin Telefon Numarası",
    ru: "Номер телефона опекуна",
    fr: "Numéro de téléphone du tuteur",
  },
  S_GUARDIAN: {
    en: "S-Guardian",
    ar: "رقم الوصي",
    tu: "Vasinin Telefon Numarası",
    ru: "Номер телефона опекуна",
    fr: "Numéro de téléphone du tuteur",
  },
  FIRST_GUARDIAN_PHONE: {
    en: "First Guardian's Phone No",
    ar: "رقم الوصي",
    tu: "Vasinin Telefon Numarası",
    ru: "Номер телефона опекуна",
    fr: "Numéro de téléphone du tuteur",
  },
  SECOND_GUARDIAN_PHONE: {
    en: "Second Guardian's Phone No",
    ar: "رقم الوصي الثاني",
    tu: "İkinci Vasinin Telefon Numarası",
    ru: "Номер телефона второго опекуна",
    fr: "Numéro de téléphone du deuxième tuteur",
  },
  SECOND_GUARDIAN: {
    en: "Second Guardian",
    ar: "الوصي الثاني",
    tu: "İkinci Vasi",
    ru: "Второй опекун",
    fr: "Deuxième gardien",
  },
  GUARDIAN: {
    en: "Guardian",
    ar: "الوصي",
    tu: "İkinci Vasi",
    ru: "Второй опекун",
    fr: "gardien",
  },
  APPROVED_BY: {
    en: "Approved By",
    ar: "الموافقة عليها من قبل",
    tu: "Onaylayan",
    ru: "Одобрено",
    fr: "Approuvé par",
  },
  DENIED_BY: {
    en: "Denied By",
    ar: "الرفض عليها من قبل",
    tu: "Onaylayan",
    ru: "Одобрено",
    fr: "Approuvé par",
  },
  FIRSTN: {
    en: "1st",
    ar: "الأول",
    tu: "1",
    ru: "1",
    fr: "1",
  },
  SECONDN: {
    en: "2st",
    ar: "الثاني",
    tu: "2",
    ru: "2",
    fr: "2",
  },
  STATUS: {
    en: "Status",
    ar: "الحاله",
    tu: "İkinci Vasi",
    ru: "Второй опекун",
    fr: "Deuxième gardien",
  },
  DATE: {
    en: "Date",
    ar: "التاريخ",
    tu: "TARİH",
    ru: "ДАТА",
    fr: "Date",
  },
  TILL: {
    en: "Till",
    ar: "حتى",
    tu: "TARİH",
    ru: "ДАТА",
    fr: "Date",
  },
  DATE_OF_BIRTH: {
    en: "Date of Birth",
    ar: "تاريخ الميلاد",
    tu: "Doğum Tarihi",
    ru: "Дата рождения",
    fr: "Date de naissance",
  },
  RELATION: {
    en: "Relation",
    ar: "العلاقة",
    tu: "ilişki",
    ru: "Связь",
    fr: "Relation",
  },
  GO_TO_HOSPITAL: {
    en: "Go-To Hospital",
    ar: "المستشفى",
    tu: "Hastaneye git",
    ru: "Идти в больницу",
    fr: "Aller à l'hôpital",
  },
  INSURANCE: {
    en: "Insurance",
    ar: "تأمين",
    tu: "Sigorta",
    ru: "Страхование",
    fr: "Assurance",
  },
  INSURANCE_ID: {
    en: "Insurance ID",
    ar: "معرف التأمين",
    tu: "Sigorta Kimliği",
    ru: "Идентификатор страхования",
    fr: "Identifiant d'assurance",
  },
  //*************INBOX_COMPONENT*************//
  ADD_NEW_MESSAGE: {
    en: "Send message",
    ar: " أرسل رساله جديده",
    tu: "Sigorta Kimliği",
    ru: "Идентификатор страхования",
    fr: "Identifiant d'assurance",
  },
  RECIEVED_RECORDS: {
    en: "Received Records",
    ar: " أرسل رساله جديده",
    tu: "Sigorta Kimliği",
    ru: "Идентификатор страхования",
    fr: "Identifiant d'assurance",
  },
  DELETE_RECORDS: {
    en: "Delete Record",
    ar: " امسح السجل",
    tu: "Sigorta Kimliği",
    ru: "Идентификатор страхования",
    fr: "Identifiant d'assurance",
  },
  VACCINE_REQUESTS: {
    en: "Vaccine Requests",
    ar: "طلبات التطعيم",
    tu: "Sigorta Kimliği",
    ru: "Идентификатор страхования",
    fr: "Identifiant d'assurance",
  },
};

export default words;
