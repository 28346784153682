import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Spinner from "react-bootstrap/Spinner";
import AuthLayout from "./components/layout/AuthLayout";
import LoginPage from "./pages/LoginPage";
import {
  loader as checkAuthLoader,
  
  tokenLoader as checkTokenLoader,
  languageLoader,
  plansLoader,
} from "./util/auth";
import Error from "./components/layout/Error";
import HomePage from "./pages/HomePage";
import SelectCountry from "./pages/SelectCountryPage";
import { useDispatch, useSelector } from "react-redux";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import { authActions } from "./store";
import { ApolloLink } from "@apollo/client/core";

const lazyRetry = function (componentImport) {
  // debugger
  return new Promise((resolve, reject) => {
    // try to import the component
    // debugger
    componentImport()
      .then((component) => {
        // debugger
        resolve(component);
      })
      .catch((error) => {
        // TO DO
        // debugger;
        console.log(error);
        window.location.reload();
        reject(error); // there was an error
      });
  });
};

const ForgotPasswordPage = lazy(() =>
  lazyRetry(() => import("./pages/ForgotPasswordPage"))
);
const WelcomePage = lazy(() => lazyRetry(() => import("./pages/WelcomePage")));
const OrganizationPage = lazy(() =>
  lazyRetry(() => import("./pages/OrganizationPage"))
);
const SignupPage = lazy(() => lazyRetry(() => import("./pages/SignupPage")));
const SubscriptionPage = lazy(() =>
  lazyRetry(() => import("./pages/SubscriptionPage"))
);
const VerifyUserPage = lazy(() =>
  lazyRetry(() => import("./pages/VerifyUserPage"))
);

const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <Spinner as="span" animation="border" variant="primary" />
  </div>
);

const router = createBrowserRouter([
  {
    path: "",
    loader: languageLoader,
    element: <HomePage />,
    errorElement: <Error />,
    children: [
      {
        path: "plans",
        exact: true,
        loader: plansLoader,
        element: (
          <Suspense fallback={Loading}>
            <SubscriptionPage />
          </Suspense>
        ),
      },
      {
        path: "auth",
        exact: true,
        loader: checkTokenLoader,
        element: <AuthLayout />,
        children: [
          { index: true, element: <LoginPage /> },
          {
            path: "forgot-password",
            element: (
              <Suspense fallback={Loading}>
                <ForgotPasswordPage />
              </Suspense>
            ),
          },
          {
            path: "sign-up",
            element: (
              <Suspense fallback={Loading}>
                <SignupPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "welcome",
        exact: true,
        element: <AuthLayout />,
        loader: checkAuthLoader,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={Loading}>
                <WelcomePage />
              </Suspense>
            ),
          },
          {
            path: "verify",
            element: (
              <Suspense fallback={Loading}>
                <VerifyUserPage />
              </Suspense>
            ),
          },
          {
            path: "new",
            element: (
              <Suspense fallback={Loading}>
                <OrganizationPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "",
        exact: true,
        loader: checkAuthLoader,
        element: <SelectCountry />,
      },
    ],
  },
]);

const getCountryLinks = (country) => {
  // debugger
  let links = {
    database:
      "https://production-eg--ayamedica-c7wpka.apollographos.net/graphql",
    monolith: "//monolith-eg-production-b341b1f6eac2.herokuapp.com/graphql",
  };

  if (country === "EG") {
    return links;
  } else if (country === "SA") {
    links = {
      database:
        "https://production-sa--ayamedica-c7wpka.apollographos.net/graphql",
      monolith: "//monolith-ksa-production-e03b7e03d1ff.herokuapp.com/graphql",
    };
    return links;
  }
  else {
    return links; 
  }
};


const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const wsLink = (language, link) =>
  new GraphQLWsLink(
    createClient({
      url: protocol + link.monolith,
      options: {
        reconnect: true,
      },
      connectionParams: {
        Authorization: sessionStorage.getItem("token"),
        language: language,
      },
    })
  );

const httpLink = (link) =>
  createUploadLink({
    uri: link.database,
    // uri: 'https://current--ayamedica-supergraph-r8r9a5.apollographos.net/graphql',
    // uri: 'http://192.168.1.20:4003/graphql',
  });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = (language, country) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink(language, getCountryLinks(country)),
    httpLink(getCountryLinks(country))
  );

const authLink = (language) =>
  setContext((_, { headers }) => {
    const token = sessionStorage.getItem("token");
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
        language: language,
        "keep-alive": "true",
        "content-type": "application/json",
      },
    };
  });

  const successLink = new ApolloLink((operation, forward) => {
    // Use the `forward` function to pass the operation down the middleware chain.
    return forward(operation).map((response) => {
      // This function is called when a response is received.
      const operationName = Object.keys(response.data)
      operationName.forEach((name) => {
        const code = response.data[name]?.code
        if (code === 450) {
          sessionStorage.clear() 
          const redirectURL = 'https://sso.ayamedica.com/'
          // const redirectURL = "http://localhost:3002"
          window.location.href = redirectURL
        }
      })
    
      // Return the response to continue the chain.
      return response;
    });
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    // debugger
    graphQLErrors.forEach(({ message, locations, path }) => {
      // debugger
      if (message === "Please login to continue") {
        sessionStorage.clear();
        window.location.reload();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const createApolloClient = (language, country) => {
  return new ApolloClient({
    link: from([
      errorLink,
      successLink,
      authLink(language).concat(splitLink(language, country)),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => (o.id ? `${o.__typename}-${o.id}` : null),
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        returnPartialData: true,
      },
      query: {
        errorPolicy: "all",
        returnPartialData: true,
      },
    },
  });
};

function App() {
  const language = useSelector((state) => state.auth.language);
  const country = useSelector((state) => state.auth.country);
  const client = createApolloClient(
    language,
    country
      ? country
      : document.location.search?.split("country=")[1]?.split("?")[0]
  );
  const dispatch = useDispatch();

  if (sessionStorage.getItem("country") === null || sessionStorage.getItem("country") === "undefined") {
    // debugger
    dispatch(
      authActions.saveCountry(
        document.location.search?.split("country=")[1]?.split("?")[0]
      )
    );
  }
  

  return (
    <ApolloProvider client={client}>
      <div
        className={language === "ar" ? "arStyles" : "enStyles"}
        lang={language === "ar" ? "ar" : undefined}
      >
        <ThemeProvider dir={language === "ar" ? "rtl" : "ltr"}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </div>
    </ApolloProvider>
  );
}

export default App;
