import { useDispatch, useSelector } from "react-redux";
import LoginForm from "../components/auth/LoginForm";
import words from "../assets/words";
import { useQuery } from "@apollo/client";
import { authActions } from "../store";
import { READ_AYAMEDICA_INFO_KEYS } from "../graphql/auth/Queries";
import classes from "./LoginPage.module.scss";

const LoginPage = () => {
  const language = useSelector((state) => state.auth.language);
  const dispatch = useDispatch();

  useQuery(READ_AYAMEDICA_INFO_KEYS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      dispatch(
        authActions.saveAyamedicaInfo({
          data: data.readAyamedicaInformationKeys?.data,
        })
      );
    },
  });

  return (
    <div className={classes.container}>

      <div className={classes.loginContainer}>
        <div className={`${classes.title} noSelect`}>

          <h3>{words.LOG_IN[language]}</h3>
          <h6>
            <span>{words.WELCOME_BACK[language] + " "}</span>
            {words.ENTER_YOUR_DETAILS[language]}
          </h6>
        </div>

        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
